import React from 'react';

import {CheckCircleIcon, CodeBracketSquareIcon} from "@heroicons/react/24/outline";

import {
    Badge,
    Button,
    Card,
    Flex, Grid,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title
} from '@tremor/react';

import {get_url} from '../../const.js'
import withLocation from "../../withLocation";
import {NoDataComponent, LoadingComponent} from "../status_components";
import {getFilterFromGetParams} from "../utils/query_utils";


class GitHubRepositoryTable extends React.Component {

    state = {
        repos: null,
        metrics: null,
        nameFilter: getFilterFromGetParams(this.props, 'name', null),
    };

    col_map = {'pass': 'emerald', 'warning': 'yellow', 'fail':'red'};

    componentDidMount() {
        fetch(get_url() + 'api/github_repositories')
            .then((response) => response.json())
            .then((repos) => {
                this.setState({repos: repos})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.repos === null || this.state.metrics === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        }
        else if (this.state.repos.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        }
        else {

            const github_repositories = this.state.metrics.github_repositories;
            // Render real UI ...
            let active_repos = this.state.repos
                .filter(item => !this.state.nameFilter || item.name.includes(this.state.nameFilter));

            return (
                <>
                    <Flex justifyContent="start">
                        <Title>GitHub Repositories: {active_repos.length}</Title>
                        <Text>&nbsp;/ {github_repositories.total_repositories}</Text>
                    </Flex>
                    {this.renderFilters()}

                    <Table className="mt-5">

                        {this.renderHeader()}

                        <TableBody>
                            {active_repos.map((item) => this.renderRow(item))}
                        </TableBody>
                    </Table>
                </>
            );
        }
    }

    renderFilters() {

        return (
            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value})
                        }
                        }/>

                    </Flex>
                </div>
            </div>
        )
    }
    
    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        Repository
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Private
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Fork
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Updated At
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Active
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderOutdated(state) {
        if (state) {
            return (<Badge color="red" tooltip="Outdated">✗</Badge>)
        } else {
            return (<Badge color="green" tooltip="Active">✓</Badge>)
        }
    }
    
    renderState(state) {
        if (state) {
            return (<Badge color="green" tooltip="Enabled">✓</Badge>)
        } else{
            return (<Badge color="red" tooltip="Disabled">✗</Badge>)
        }
    }
    
    renderRow(repo) {
        return (
            <TableRow key={repo.name}>
                <TableCell>
                    {repo.name}
                </TableCell>
                <TableCell className="text-center">
                {this.renderState(repo.is_private)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderState(repo.is_fork)}
                </TableCell>
                <TableCell className="text-center">
                    {repo.updated_at}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderOutdated(repo.is_outdated)}
                </TableCell>
                
                <TableCell className="text-center">
                    <Flex
                        justifyContent="start"
                        className="space-x-3"
                    >
                        <Button variant='light'
                            icon={CodeBracketSquareIcon}
                            color="blue"
                            onClick={() => window.open(repo.url, '_blank', 'noopener,noreferrer')}
                        >
                            GitHub
                        </Button>
                    </Flex>
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(GitHubRepositoryTable)
