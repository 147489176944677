import React, { Component } from 'react';
import { TableCell, TableHead, TableHeaderCell, TableRow, Table } from "@tremor/react";
import { TooltipText } from "../text_compontents";
import { TableBody } from '@tremor/react';
import { Grid } from '@tremor/react';
class ConfigRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], // Assuming the data fetched from the API will be stored in this state
        };
    }

    componentDidMount() {
        // Assuming you want to make the API call when the component mounts
        this.executePythonMethod(this.props.account_id_parameter);
    }

    executePythonMethod = async (parameter) => {
        try {
            const response = await fetch(`api/config/non_compliant_rules/${parameter}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            this.setState({ data }); // Assuming the data should be stored in the component's state
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchDataForAccount = (account_id) => {
        this.executePythonMethod(account_id);
    };

    render() {

        return (
            <Grid numColsMd={1} className="gap-x-6 gap-y-6 mt-6">
                <Table className="mt-5">
                    {this.renderHeader()}
                    <TableBody>
                        {this.state.data.map((item) => this.renderRow(item))}
                    </TableBody>
                </Table>
            </Grid>
        );
    }

    renderHeader() {

        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Rule Name" tooltip="AWS Config Rule Name" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Non Compliant Resources" tooltip="Retrieves if the Config Rule is compliant" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Description" tooltip="Rule description if applies" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Last Recorded Time" tooltip="When the Config rule has been evaluated" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>
        );
    }

    renderRow(item) {
        return (
            <TableRow>
                <TableCell className="text-center">
                    {item.rule_name}
                </TableCell>
                <TableCell className="text-center">
                    {item.non_compliance_resources}
                </TableCell>
                <TableCell className="text-center">
                    {item.description && item.description.length > 80
                        ? `${item.description.substring(0, 80)}...`
                        : item.description}
                </TableCell>
                <TableCell className="text-center">
                    {item.date}
                </TableCell>
            </TableRow>
        );
    }
}

export default ConfigRules;
