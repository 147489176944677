import React from 'react';

import {
    Button, Card, Divider, Flex, Text, Title
} from '@tremor/react';
import {get_url, SHOW_LOGO} from "../const";
import {TicketIcon, GlobeAltIcon} from "@heroicons/react/24/outline";

export default class HelpModal extends React.Component {

    // A simple dialog component that shows help information

    render() {
        return (
            <div style={{
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                position: 'fixed',
                display: 'flex',
                zIndex: 1
            }}>
                <div style={{display: 'flex', zIndex: 1, margin: "auto auto"}}>
                    <Card>
                        <Flex justifyContent="start">
                            {SHOW_LOGO ? (<img src="/logo192.png" width="32px" height="32px" alt="Porkpie Logo"/>) : (<></>)}
                            <Title>Security Automation Dashboard</Title>
                        </Flex>
                        <Divider/>


                        <Text className="mt-3">
                            <p>Welcome to Porkpie, the Security Automation Dashboard.</p>

                            <p>You can learn more about this dashboard in our Wiki:</p>

                            <Button
                                    icon={GlobeAltIcon}
                                    color="blue"
                                    onClick={() => window.open('https://seceng.ebury.rocks/', '_blank', 'noopener,noreferrer')}
                            >
                                Wiki
                            </Button>

                            <p>You can use the buttons below to raise bug reports and feature requests directly on our
                                Jira board.</p>
                        </Text>

                        <Flex justifyContent="start" className="mt-3 space-x-3">
                            <Button
                                icon={TicketIcon}
                                color="blue"
                                onClick={() => window.open('https://fxsolutions.atlassian.net/jira/secure/CreateIssue.jspa?issuetype=10006&pid=15556', '_blank', 'noopener,noreferrer')}
                            >
                                Bug Ticket
                            </Button>

                            <Button
                                icon={TicketIcon}
                                color="blue"
                                onClick={() => window.open('https://fxsolutions.atlassian.net/jira/secure/CreateIssue.jspa?issuetype=10006&pid=15556', '_blank', 'noopener,noreferrer')}
                            >
                                Feature Request
                            </Button>
                        </Flex>

                        <Divider/>
                        <Flex>
                            <Text className="mt-3">Env: {process.env.NODE_ENV} <br/>URL: {get_url()}</Text>
                            <Button color="neutral" onClick={this.props.onclose}>Close</Button>
                        </Flex>
                    </Card>
                </div>
            </div>
        )
    }
}