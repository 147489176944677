import {
    Button,
    Flex
} from '@tremor/react';
import React from "react";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";

export default function ErrorPage() {
    return (
        <div className="max-w-7xl min-h-screen mx-auto px-6 sm:px-8"
             style={{"width": "75%", margin: "auto", marginTop: "20px", textAlign: "center"}}>
            <main>
                <Flex justifyContent={"center"} className={"space-x-4"}>
                    <div id="error-page">
                        <img src="/porkpie404.png" alt="Sad Porkpie"/>
                        <Button variant='light'
                            icon={ChevronLeftIcon}
                            color="blue"
                            onClick={() => window.location = '/'}
                        >
                            Go Home
                        </Button>
                        <h1 style={{fontSize: "400%", color: "#777"}}>Sorry there was an error</h1>
                    </div>
                </Flex>
            </main>
        </div>
    );
}