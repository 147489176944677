import './App.css';

import {QuestionMarkCircleIcon, ArrowDownTrayIcon, EllipsisVerticalIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {Button, Flex, Tab, TabList, Text, Title} from '@tremor/react';

import {get_url, routes, SHOW_LOGO} from "./const"

import React from "react";
import ConnectionTest from "./components/connection_component";
import HelpModal from "./components/help_dialog";
import {downloadElementAs} from "./components/utils/downloader";
import withLocation from "./withLocation";
import {set_logged_out} from "./auth";

class App extends React.Component {

    state = {
        selectedView: this.props.activeTab,
        show_about_modal: false,
        show_download_options: false,
        user: null
    }

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.printRef = React.createRef();
    }

    componentDidMount() {
        if (localStorage.getItem('user')){
            // Already have current user, no need to refetch
            this.setState({user: localStorage.getItem('user')})
            return
        }

        fetch(get_url()+'api/user')
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem('user', data.user)
                this.setState({user: data.user})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    showModal = () => {
        this.setState({show_about_modal: true});
    };

    hideModal = () => {
        this.setState({show_about_modal: false});
    };

    showDownloadOptions = () => {
        this.setState({show_download_options: true});
    };

    renderTab(tab) {
        if (tab < routes.length) {
            return routes[tab].view;
        }
        return (<Text>Error</Text>);
    }

    showPrintView(show) {
        document.querySelectorAll('.hiddenPrint').forEach(el => {
            el.style.display = show ? "none" : null;
        });
    }

    handleDownload = async (type) => {
        await downloadElementAs(type, this.printRef.current);
        this.setState({show_download_options: false});
    }

    changeTab(value) {
        // Update URL bar to the new tab route
        this.props.navigate(routes[value].route)
        this.setState({
            selectedView: value,
            show_download_options: false
        })
    }

    render() {
        if (!this.state.user){
            // Loading...
            return
        }

        let active_tab = this.state.selectedView;
        let name = this.state.user.split('.')[0]
        name = name.charAt(0).toUpperCase() + name.slice(1)

        return (
            <div className="min-h-screen mx-auto px-6 sm:px-8"
                 style={{"width": "88%", margin: "auto", marginTop: "20px"}}>
                <main>
                    {this.state.show_about_modal ? (<HelpModal onclose={this.hideModal}/>) : <></>}

                    <Flex>
                        <Flex justifyContent={"start"} className={"space-x-4"}>

                            {SHOW_LOGO ? (<img src="/logo192.png" width="96px" height="96px" alt="Porkpie Logo"
                                               onClick={this.showModal}/>) : (<></>)}

                            <div>
                                <Title>Porkpie Dashboard</Title>
                                <Text>Gathering and visualising security KPI metrics</Text>
                                <ConnectionTest/>
                            </div>

                        </Flex>
                        <div>
                            <Flex>
                                <Button onClick={this.showModal} icon={QuestionMarkCircleIcon}>Help</Button>
                                <Button className="ml-2" onClick={() => {
                                    set_logged_out();
                                    window.location = '/logout';
                                }}>Logout, {name}</Button><br/>
                            </Flex>
                        </div>
                    </Flex>

                    <div style={{display: 'flex'}}>
                        <div style={{width: "100%"}}>
                            <TabList defaultValue={active_tab} className="mt-6"
                                     onValueChange={(value) => this.changeTab(value)}>
                                <Tab value={0} text="Overview"/>
                                <Tab value={1} text="Domains"/>
                                {/*<Tab value={2} text="Vulnerabilities"/>*/}
                                <Tab value={2} text="Office Networks"/>
                                <Tab value={3} text="GitHub"/>
                                <Tab value={4} text="Monitoring"/>
                                <Tab value={5} text="Endpoint Protection"/>
                                <Tab value={6} text="AWS IAM"/>
                                <Tab value={7} text="AWS Compliance"/>
                            </TabList>
                        </div>

                        {/* Download/Export Options */}
                        <div style={{
                            alignItems: "flex-end",
                            display: "inline-block",
                            alignSelf: "flex-end",
                            background: "white",
                            padding: "10px",
                            borderRadius: "10px",
                            borderColor: "rgb(229 231 235)",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}>
                            {!this.state.show_download_options ? (
                                <Button color="zinc" onClick={this.showDownloadOptions}
                                        icon={EllipsisVerticalIcon} variant="light">Export</Button>
                            ) : (
                                <Flex className="space-x-2">
                                    <Button className="download_button" color="emerald" onClick={() => {
                                        this.handleDownload('png')
                                    }}
                                            icon={ArrowDownTrayIcon} variant="light">Image</Button>
                                    <Button className="download_button" color="rose" onClick={() => {
                                        this.handleDownload('pdf')
                                    }}
                                            icon={ArrowDownTrayIcon} variant="light">PDF</Button>
                                    <Button className="download_button" color="sky" onClick={() => {
                                        this.handleDownload('csv')
                                    }}
                                            icon={ArrowDownTrayIcon}
                                            disabled={routes[this.state.selectedView].disable_csv === true}
                                            variant="light">CSV</Button>
                                    <Button color="zinc" onClick={() => {
                                        this.setState({show_download_options: false})
                                    }}
                                            icon={XMarkIcon} variant="light"></Button>
                                </Flex>
                            )}
                        </div>
                    </div>

                    <div ref={this.printRef}>
                        {this.renderTab(this.state.selectedView)}
                    </div>

                    <Flex className="mt-6"/>
                </main>
            </div>
        );
    }

}

export default withLocation(App);