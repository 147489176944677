import {Card, Flex, Text, Title} from "@tremor/react";
import React from "react";

export function ErrorComponent(props) {
    return (
        <Card className="mt-6">
            <Title>Error!</Title>
        </Card>
    );
}

export function NoDataComponent(props) {
    return (
        <Card className="mt-6">
            <Title>No Data</Title>
        </Card>
    );
}

export function LoadingComponent(props) {
    return (
        <Card className="mt-6">
            <Flex justifyContent="start" className="space-x-2">
                <img className="App-logo" src="/logo192.png" width="32px" height="32px" alt="Porkpie Logo" style={{opacity: 0.5}}/>
                <Text>Loading...</Text>
            </Flex>
        </Card>
    );
}
