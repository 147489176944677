import {
    Italic,
    Card,
    DonutChart,
    Text,
    Metric,
    Flex,
    BarChart,
    ProgressBar,
    Button,
    Legend,
    AccordionList,
    Accordion,
    AccordionHeader, AccordionBody, Callout, Icon, Title, Subtitle, LineChart, BadgeDelta
} from "@tremor/react";

import {DeeplinkArrow} from "./text_compontents";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import {React, useState} from "react";

const cardWidth = "max-w-xl";
const cardWidthWide = "max-w-6xl";

export function KPICard(contents, hide_card = false, width=cardWidth){
    if (hide_card === true){
        return (<div>{contents}</div>)
    }
    return (
        <Card className={width}>
            {contents}
        </Card>)
}

export function KPICardWide(contents, hide_card = false){
    return KPICard(contents, hide_card, cardWidthWide)
}


export function KPIDecorationColor(metric, green, yellow, inverse = false) {
    if (inverse) {
        if (metric >= green) {
            return "green";
        } else if (metric >= yellow) {
            return "yellow";
        } else {
            return "red";
        }
    } else {
        if (metric <= green) {
            return "green";
        } else if (metric <= yellow) {
            return "yellow";
        } else {
            return "red";
        }
    }
}

export function KPICard1Metric(data) {
    let decoration = data.color ? "top" : "unset";

    return (
        <Card className={cardWidth} decoration={decoration} decorationColor={data.color}>
            <Title>{data.title}</Title>
            <Metric>{data.value}</Metric>
        </Card>
    );
}

export function KPICardMetrics(data) {
    let decoration = data.color ? "top" : "unset";

    return (
        <Card className={cardWidth} decoration={decoration} decorationColor={data.color} style={{ "display": "flex", "align-items": "center"}}>
            <Flex>
                <div>
                    {data.icon ? <Icon icon={data.icon} /> : <></>}
                    <Title className="text-2xl">{data.title}</Title>
                    {data.subtitle ? <Subtitle className="mt-4">{data.subtitle}</Subtitle> : <></>}

                    {data.items.map((item) => (
                        <Flex justifyContent='start' className='mt-2'>
                            {item.icon ? <Icon color={item.icon_color} icon={item.icon}/> : <></>}
                            <Title className="text-gray-500">{item.metric}</Title>
                            {item.total ? <Text>&nbsp;/ {item.total}</Text> : <></>}
                            <Title className="ms-2 text-gray-500">{item.title}</Title>
                            {item.location ? <DeeplinkArrow location={item.location}/> : <></>}
                        </Flex>
                    ))}
                </div>
                <div>
                    <Flex justifyContent="end">
                        {data.title_icon ? <Icon color={data.title_icon_color} icon={data.title_icon} size="lg"/> : <></>}
                        <Metric className="text-3xl"> {data.title_metric}</Metric>
                        {data.title_metric_total ? <Text>&nbsp;/ {data.title_metric_total}</Text> : <></>}
                        {data.title_location ? <DeeplinkArrow location={data.title_location}/> : <></>}
                    </Flex>
                </div>
            </Flex>

        </Card>
    );
}

export function KPICardMetricsWithPercentIncrease(data) {
    let decoration = data.color ? "top" : "unset";
    let difference = (((data.today-data.first_month["Compliance %"])*100) / data.first_month["Compliance %"])

    return(
        <Card className={cardWidth} decoration={decoration} decorationColor={data.color}>

            <Flex justifyContent="between" alignItems="center">
                <Title className="text-2xl">{data.title}</Title>
                { difference >= 0 ? (
              <BadgeDelta deltaType="moderateIncrease" size="xs">
                  {"+" + difference.toFixed(0) + "%"}
              </BadgeDelta> ) :
                <BadgeDelta deltaType="moderateDecrease" size="xs">
                  {"-" + difference.toFixed(0) + "%"}
              </BadgeDelta> }
            </Flex>
            <Flex>
                <div>
                    {data.icon ? <Icon icon={data.icon} /> : <></>}
                    {data.subtitle ? <Subtitle className="mt-4">{data.subtitle}</Subtitle> : <></>}

                    {data.items.map((item) => (
                        <Flex justifyContent='start' className='mt-2'>
                            {item.icon ? <Icon color={item.icon_color} icon={item.icon}/> : <></>}
                            <Title className="text-gray-500">{item.metric}</Title>
                            {item.total ? <Text>&nbsp;/ {item.total}</Text> : <></>}
                            <Title className="ms-2 text-gray-500">{item.title}</Title>
                            {item.location ? <DeeplinkArrow location={item.location}/> : <></>}
                        </Flex>
                    ))}
                </div>
                <div>
                    <Flex justifyContent="end">
                        {data.title_icon ? <Icon color={data.title_icon_color} icon={data.title_icon} size="lg"/> : <></>}
                        <Metric className="text-3xl"> {data.title_metric}</Metric>
                        {data.title_metric_total ? <Text>&nbsp;/ {data.title_metric_total}</Text> : <></>}
                        {data.title_location ? <DeeplinkArrow location={data.title_location}/> : <></>}
                    </Flex>
                </div>
            </Flex>
        </Card>
    );
}

export function KPIDonut(data) {
    let legend;

    if (data.legend){
        const cats = data.data.map((item) => item["name"]);

        legend = (
            <Legend
                categories={data.reverse ? cats.reverse() : cats}
                colors={data.legend_colors ? data.legend_colors : data.colors}
                className='mt-10'
            />
        )
    }

    let chartCardWidth;
    if (data.cardWidth){
        chartCardWidth = data.cardWidth;
    }else{
        chartCardWidth = cardWidth;
    }

    return (
        <div style={{ "display": "flex", "align-items": "center"}}>
            <Card className={chartCardWidth} >
                    <Title>{data.title}</Title>
                    <Subtitle>{data.subtitle}</Subtitle>
                    <DonutChart
                        data={data.data}
                        category="value"
                        index="name"
                        className="mt-20"
                        showLabel={true}
                        colors={data.colors}
                    />
                    {legend}
            </Card>
        </div>
    );
}

export function KPIDonutNoLabel(data) {
    let legend;

    if (data.legend){
        const cats = data.data.map((item) => item["name"]);

        legend = (
            <Legend
                categories={data.reverse ? cats.reverse() : cats}
                colors={data.legend_colors ? data.legend_colors : data.colors}
                className='mt-10'
            />
        )
    }

    return (
        <div style={{ "display": "flex", "align-items": "center"}}>
            <Card className={cardWidth} >
                    <Title>{data.title}</Title>
                    <DonutChart
                        data={data.data}
                        category="value"
                        index="name"
                        className="mt-20"
                        showLabel={false}
                        colors={data.colors}
                    />
                    {legend}
            </Card>
        </div>
    );
}

export function KPIAccordianDomains(data) {
    if (!data.color){
        data.color = "teal"
    }

    let percentValue = Math.round(100 * data.value/data.total);

    return KPICard(
        <>
            <Flex className="space-x-1 mt-3" alignItems="items-center">
                <Title>{data.title}</Title>
                <Icon icon={data.icon} size="lg"/>
            </Flex>

            <Flex className="space-x-1 mt-3">
                <Metric>{percentValue}%</Metric>
                <Flex justifyContent="end" className="space-x-1" alignItems="items-baseline">
                    <Metric> {data.value} </Metric>
                    <Text>/ {data.total}</Text>
                </Flex>
            </Flex>
            <ProgressBar percentageValue={percentValue} color={data.color} className="mt-2"/>
            <Card className="mt-2">
                { data.data.map((item) => (
                    <Flex className="mt-4 mb-4">
                            <div style={{width:  "95%"}}>
                                <Flex >
                                    <Text>{ item.name }</Text>
                                    <Text className="truncate">{ `${Math.round(item.value)} %` }</Text>
                                </Flex>
                                <ProgressBar percentageValue={ Math.round(item.value)} color={data.color}/>
                            </div>
                            <div>
                                {item.location ? <DeeplinkArrow location={item.location}/> : <></>}
                            </div>
                    </Flex>
                )) }

                { data.extra ? data.extra : (<></>) }

            </Card>
        </>
    , data.hide_card);
}

export function KPIAccordianProgressList(data) {
    if (!data.color){
        data.color = "green"
    }

    let percentValue = Math.round(100 * data.value/data.total);

    return KPICard(
        <>
            <Flex className="space-x-1 mt-3" alignItems="items-center">
                <Title>{data.title}</Title>
                <Icon icon={data.icon} size="lg"/>
            </Flex>

            <Flex className="space-x-1 mt-3">
                <Metric>{percentValue}%</Metric>
                <Flex justifyContent="end" className="space-x-1" alignItems="items-baseline">
                    <Metric> {data.value} </Metric>
                    <Text>/ {data.total}</Text>
                </Flex>
            </Flex>
            <ProgressBar percentageValue={percentValue} color={data.color} className="mt-2"/>
            <Text className="mt-4">
                {data.description}
            </Text>
            <AccordionList shadow={ false } className="mt-6">
                { data.data.map((item) => (
                    <Accordion key={ item.name }>
                        <AccordionHeader>
                            <div style={{width:  "100%"}}>
                                <Flex >
                                    <Text>{ item.name }</Text>
                                    <Text className="truncate">{ `${Math.round(item.value)} %` }</Text>
                                </Flex>
                                <ProgressBar percentageValue={ Math.round(item.value) }/>
                            </div>
                        </AccordionHeader>
                        <AccordionBody>
                            <Callout
                                color="gray"
                                className="mt-2"
                                title={undefined}
                            >
                                { item.description }
                            </Callout>
                        </AccordionBody>
                    </Accordion>
                )) }

                { data.extra ? data.extra : (<></>) }

            </AccordionList>
        </>
    , data.hide_card);
}

export function KPIBarChart(data) {

    let chartCardWidth;
    if (data.cardWidth){
        chartCardWidth = data.cardWidth;
    }else{
        chartCardWidth = cardWidth;
    }

    let chartLayout;
    if (data.layout) {
        chartLayout = data.layout;
    }else{
        chartLayout = "horizontal"
    }


    return (
        <Card className={chartCardWidth}>
            <Title>{data.title}</Title>
            <Subtitle>{data.subtitle}</Subtitle>
            <BarChart
                data={data.data}
                categories={data.categories}
                index={data.index}
                colors={data.colors}
                valueFormatter={data.valueFormatter}
                layout={chartLayout}
                stack={data.stack}
                relative={false}
                startEndOnly={false}
                showXAxis={true}
                showYAxis={true}
                autoMinValue={false}
                showTooltip={true}
                showLegend={data.legend}
                showGridLines={true}
                showAnimation={true}
                className="h-80 mt-3"
            />
        </Card>
    );
}

export function ColouredBarChart(data) {
    return KPICard(
        <>
            <Title>{ data.title }</Title>
            <Text>{ data.subtitle }</Text>
            <BarChart
                className="mt-4 h-80"
                data={ data.data }
                index="date"
                categories={ data.categories }
                colors={ ['sky', 'violet', 'fuchsia'] }
                stack={ true }
                relative={ true }
            />
        </>
    , data.hide_card);
}

export function VulnsSourcesStackedBarChart(data) {
    let vuln_month_cloud = []
    let categories = []

    for (const key of data.data) {
        let store_dict = {}
        store_dict["date"] = key["date"]
        for (let cloud in key["cloud_sources"]){
            if (cloud) {
                store_dict[cloud] = key["cloud_sources"][cloud]
                if (!categories.includes(cloud)) {
                    categories.push(cloud)
                }
            }
        }
        vuln_month_cloud.push(store_dict)
    }

    // // // Display only the last x months
    vuln_month_cloud = vuln_month_cloud.slice(data.months)

    let content = (
        <>
            <Title>{ data.title }</Title>
            <Text>{ data.subtitle }</Text>
            <BarChart
                className="mt-4 h-80"
                data={ vuln_month_cloud }
                index="date"
                // Our categories / colors are reversed here so the more critical goes on top
                categories={ categories }
                colors={ ["cyan","rose", "amber", "stone",  "green", "violet", "pink", "fuchsia"] }
                stack={ true }
                relative={ false }
                showLegend={true}
            />
        </>
    )

    return KPICardWide(content, data.hide_card)
}

export function VulnsStackedBarChart(data) {
    let graph_data = [];
    for (const key of data.data) {
            // Massage the data into the format the BarChart expects
            graph_data.push(
                {
                    'date': key['date'],
                    "UNCLASSIFIED": key["severities"]['UNCLASSIFIED'],
                    "LOW": key["severities"]['LOW'],
                    "MEDIUM": key["severities"]['MEDIUM'],
                    "HIGH": key["severities"]['HIGH'],
                    "CRITICAL": key["severities"]['CRITICAL'],
                }
            )
    }

    // Display only the last x months
    graph_data = graph_data.slice(data.months)

    let content = (
        <>
            <Title>{ data.title }</Title>
            <Text>{ data.subtitle }</Text>
            <BarChart
                className="mt-4 h-80"
                data={ graph_data }
                index="date"
                // Our categories / colors are reversed here so the more critical goes on top
                categories={ ['UNCLASSIFIED','LOW', 'MEDIUM', 'HIGH', 'CRITICAL'] }
                colors={ ['gray', 'green', 'amber', 'red', 'violet'] }
                stack={ true }
                relative={ false }
            />
        </>
    )

    return KPICardWide(content, data.hide_card)
}

export function BitsightLineGraph(data) {
    let recent_rating
    let recent_rating_index
    let difference
    let second_last_rating

    // check score for most recent month
    for (let i = 1; i < data.data.length-1; i++) {
        if ("score" in data.data[data.data.length-i]) {
            recent_rating = data.data[data.data.length-i]
            recent_rating_index = i
            break;
        }
    }


    // get second most recent rating for % increase/decrease calculation
    if (recent_rating){
        for (let i = recent_rating_index + 1; i < data.data.length-1; i++){
            if ("score" in data.data[data.data.length-i]){
                second_last_rating = data.data[data.data.length-i].score
                break;
            }
        }
    }

    // calculate % increase/decrease
    if (recent_rating && second_last_rating) {
        difference = (((recent_rating.score-second_last_rating)*100) / second_last_rating)
    } else{
        difference = 0
    }

    return(
        <Card>
            <Flex justifyContent="between" alignItems="center">
                <Title>{data.title}</Title>
                { difference >= 0 ? (
              <BadgeDelta deltaType="moderateIncrease" size="xs">
                  {"+" + difference.toFixed(0) + "%"}
              </BadgeDelta> ) :
                <BadgeDelta deltaType="moderateDecrease" size="xs">
                  {"-" + difference.toFixed(0) + "%"}
              </BadgeDelta> }
            </Flex>
            <Flex justifyContent="between">
                <Metric>{recent_rating.score}</Metric>
                <Text>{"Latest Score: " + recent_rating.rating_date}</Text>
            </Flex>
            <LineChart
                className="mt-6"
                data={data.data}
                index="date"
                categories={["score"]}
                colors={['pink']}
                yAxisWidth={40}
                curveType={"linear"}
                connectNulls={true}
                showAnimation={true}
            />
        </Card>)}

export function ConfigLineGraph(data) {

    return(
        <Card className="gap-x-6 gap-y-6 mt-6">
        <Flex justifyContent="between" alignItems="center">
            <Title>{data.title}</Title>
        </Flex>

            <LineChart
                className="mt-6"
                data={data.data}
                index="date"
                categories={["Compliance %"]}
                colors={['pink']}
                yAxisWidth={40}
                curveType={"linear"}
                connectNulls={true}
                showAnimation={true}
            />
        </Card>)}

export function createFooter(text, action) {
    return (
        <Flex justifyContent="end" className="p-3 border-t border-slate-200 hiddenPrint">
            <Button variant='light'
                size="sm"
                icon={ArrowRightIcon}
                iconPosition="right"
                onClick={action}
            >
                {text}
            </Button>
        </Flex>
    )
}
