import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

function withLocation(WrappedComponent) {
    return function LocationComponent(props) {
        const location = useLocation();
        const navigate = useNavigate();
        return <WrappedComponent {...props} location={location} navigate={navigate} />;
    };
}

export default withLocation;