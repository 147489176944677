import React from 'react';

import {GlobeAltIcon, WindowIcon} from "@heroicons/react/24/solid";

import {
    Badge,
    Button,
    Card,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Grid,
    Title,
    Col,
    Italic
} from '@tremor/react';

import {
    get_url,
    STRING_ALL_DOMAINS,
    STRING_DKIM,
    STRING_DMARC,
    STRING_IP_ADDRESS,
    STRING_SPF
} from '../../const.js'
import {newlineRender, booleanToText} from "../utils/text_utils";
import {TooltipText} from "../text_compontents";
import withLocation from "../../withLocation";
import {getFilterFromGetParams} from "../utils/query_utils";
import {createFooter, KPIAccordianDomains, KPIDonut} from "../kpi_components";
import {EnvelopeIcon} from "@heroicons/react/24/outline";
import {NoDataComponent, LoadingComponent} from "../status_components";


class DomainTable extends React.Component {

    state = {
        domains: null,
        metrics: null,
        dates: null,
        nameFilter: getFilterFromGetParams(this.props, 'name', null),
        selectedStates: getFilterFromGetParams(this.props, 'status', []),
        selectedSources: getFilterFromGetParams(this.props,'sources', []),
        selectedExpiring: getFilterFromGetParams(this.props, 'expiring', []),
        selectedExpired: getFilterFromGetParams(this.props, 'expired', []),
        selectedAutoRenew: getFilterFromGetParams(this.props, 'autorenew', []),
        selectedMx: getFilterFromGetParams(this.props, 'mx', []),
        selectedSpf: getFilterFromGetParams(this.props, 'spf', []),
        selectedDkim: getFilterFromGetParams(this.props, 'dkim', []),
        selectedDmarc: getFilterFromGetParams(this.props, 'dmarc', []),
        selectedPublicIP: getFilterFromGetParams(this.props, 'public_ip', []),
        selectedTLSExpiring: getFilterFromGetParams(this.props, 'tls_expiring', []),
    };

    col_map = {'pass': 'emerald', 'warning': 'yellow', 'fail':'red'};


    componentDidMount() {
        fetch(get_url() + 'api/domains')
            .then((response) => response.json())
            .then((domains) => {
                // FIXME: Filter uniquely by domains name (remove duplicates) (API should return unique only)
                // domains = Array.from(new Map(domains.map((item) => [item["name"], item])).values());
                this.setState({domains: domains})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({dates: dates})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.domains === null || this.state.metrics === null || this.state.dates === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        }
        else if (this.state.domains.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        }
        else {

            let dates = this.state.dates;

            let domain_sources = []
                for (let key of Object.keys(this.state.metrics.domains.sources)) {
                    domain_sources.push({name: key, value: this.state.metrics.domains.sources[key]})
                }
            let domains = this.state.metrics.domains;


            // Render real UI ...
            let active_domains = this.state.domains
                .filter(item => !this.state.nameFilter || item.name.includes(this.state.nameFilter))
                .filter(item => item.has_private_zone == 0)
                .filter(item => this.state.selectedStates.length === 0 || this.state.selectedStates.includes(item.status))
                .filter(item => this.state.selectedSources.length === 0 || this.state.selectedSources.includes(item.source))
                .filter(item => this.state.selectedExpiring.length === 0 || this.state.selectedExpiring.includes(item.expiring.toString()))
                .filter(item => this.state.selectedExpired.length === 0 || this.state.selectedExpired.includes(item.expired.toString()))
                .filter(item => this.state.selectedAutoRenew.length === 0 || this.state.selectedAutoRenew.includes(item.auto_renew.toString()))
                .filter(item => this.state.selectedMx.length === 0 || this.state.selectedMx.includes(item.has_googlemail.toString()))
                .filter(item => this.state.selectedSpf.length === 0 || this.state.selectedSpf.includes(item.spf_valid.toString()))
                .filter(item => this.state.selectedDkim.length === 0 || this.state.selectedDkim.includes(item.dkim_valid.toString()))
                .filter(item => this.state.selectedDmarc.length === 0 || this.state.selectedDmarc.includes(item.dmarc_valid.toString()))
                .filter(item => this.state.selectedTLSExpiring.length === 0 || this.state.selectedTLSExpiring.includes(item.tls_expiring.toString()))
                .filter(item => this.state.selectedPublicIP.length === 0 || this.state.selectedPublicIP.includes(item.has_private_ip.toString()));


            return (
                <>
                    <Grid numColsMd={3} className="gap-x-6 gap-y-6 mt-6">

                        { KPIDonut({
                            title: 'Domains Sources', data: domain_sources,
                            'legend': true,
                            'colors': ["yellow", "blue", "red", "green", "violet", "cyan", "slate"]
                        })}

                        { KPIAccordianDomains({
                            title: 'AWS Prod: Securely Configured Mailing Domains',
                            icon: EnvelopeIcon,
                            color: 'blue',
                            value: domains.prod.has_mx_spf_dkim_dmarc,
                            total: domains.prod.total_prod_mailing,
                            description: STRING_ALL_DOMAINS,
                            data:
                                [
                                    {
                                        name: 'SPF',
                                        value: 100 * domains.prod.has_spf / domains.prod.total_prod_mailing,
                                        location: "/domains?sources=aws-prod&mx=true&spf=true",
                                        description: STRING_SPF
                                    },
                                    {
                                        name: 'DKIM',
                                        value: 100 * domains.prod.dkim_valid / domains.prod.total_prod_mailing,
                                        location: "/domains?sources=aws-prod&mx=true&dkim=true",
                                        description: STRING_DKIM
                                    },
                                    {
                                        name: 'DMARC',
                                        value: 100 * domains.prod.dmarc_valid / domains.prod.total_prod_mailing,
                                        location: "/domains?sources=aws-prod&mx=true&dmarc=true",
                                        description: STRING_DMARC
                                    },
                                    {
                                        name: 'Public IP Address',
                                        value: 100 * domains.prod.ip_address_public / domains.prod.total_prod_mailing,
                                        location: "/domains?sources=aws-prod&mx=true&public_ip=true",
                                        description: STRING_IP_ADDRESS
                                    },
                                ],
                        extra: createFooter("View", ()=>{window.location='/domains?sources=aws-prod&spf=true&dkim=true&dmarc=true'})
                    })}


                        {KPIAccordianDomains({
                            title: 'Securely Configured Mailing Domains',
                            icon: EnvelopeIcon,
                            color: 'blue',
                            value: domains.all_mailing_domains.has_mx_spf_dkim_dmarc,
                            total: domains.all_mailing_domains.total_mailing,
                            description: STRING_ALL_DOMAINS,
                            data:
                                [
                                    {
                                        name: 'SPF',
                                        value: 100 * domains.all_mailing_domains.has_spf / domains.all_mailing_domains.total_mailing,
                                        location: "/domains?spf=false&mx=true",
                                        description: STRING_SPF
                                    },
                                    {
                                        name: 'DKIM',
                                        value: 100 * domains.all_mailing_domains.dkim_valid / domains.all_mailing_domains.total_mailing,
                                        location: "/domains?dkim=false&mx=true",
                                        description: STRING_DKIM
                                    },
                                    {
                                        name: 'DMARC',
                                        value: 100 * domains.all_mailing_domains.dmarc_valid / domains.all_mailing_domains.total_mailing,
                                        location: "/domains?dmarc=false&mx=true",
                                        description: STRING_DMARC
                                    },
                                    {
                                        name: 'Public IP Address',
                                        value: 100 * domains.all_mailing_domains.ip_address_public / domains.all_mailing_domains.total_mailing,
                                        location: "/domains?public_ip=true&mx=true",
                                        description: STRING_IP_ADDRESS
                                    },
                                ],
                        extra: createFooter("View", ()=>{window.location='/domains?spf=true&dkim=true&dmarc=true&mx=true'})
                    })}

                    </Grid>
                <Col numColSpanLg={2}>
                    <Card className="mt-6">
                        <Flex justifyContent='end'>
                            <div>
                                <Text><Italic>Updated on: {dates.get_latest_date_domains}</Italic></Text>
                            </div>
                        </Flex>
                        <Flex justifyContent='start'>
                            &nbsp;
                            <Title>Domains: {active_domains.length}</Title><Text>&nbsp;/ {domains.num_domains}</Text>
                        </Flex>


                        {this.renderFilters()}

                        <Table className="mt-5">
                            {this.renderHeader()}
                            <TableBody>
                                {active_domains.map((item) => this.renderRow(item))}
                            </TableBody>
                        </Table>
                    </Card>
                </Col>
                </>
            );
        }
    }

    renderFilters() {
        let sources = Array.from(new Map(this.state.domains.map((item) => [item["source"], item["source"]])).values());
        let statuses = Array.from(new Map(this.state.domains.map((item) => [item["status"], item["status"]])).values());
        let expiring = Array.from(new Map(this.state.domains.map((item) => [item["expiring"], item["expiring"]])).values());
        let expired = Array.from(new Map(this.state.domains.map((item) => [item["expired"], item["expired"]])).values());
        let tls_expiring = Array.from(new Map(this.state.domains.map((item) => [item["tls_expiring"], item["tls_expiring"]])).values());
        let auto_renew = Array.from(new Map(this.state.domains.map((item) => [item["auto_renew"], item["auto_renew"]])).values());

        return (
            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value})
                        }
                        } />

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedStates: value})}
                            placeholder="Status"
                            className="max-w-xs"
                        >
                            {statuses.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedSources: value})}
                            placeholder="Sources"
                            className="max-w-xs"
                        >
                            {sources.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedExpiring: value})}
                            placeholder="Expiring"
                            className="max-w-xs"
                        >
                            {expiring.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedExpired: value})}
                            placeholder="Expired"
                            className="max-w-xs"
                        >
                            {expired.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedAutoRenew: value})}
                            placeholder="Auto-Renew"
                            className="max-w-xs"
                        >
                            {auto_renew.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)} />
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedTLSExpiring: value})}
                            placeholder="TLS Cert Expiring"
                            className="max-w-xs"
                        >
                            {tls_expiring.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)} />
                            ))}
                        </MultiSelectBox>
                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        <TooltipText text="Name" tooltip="Name of Ebury domains" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Source" tooltip="Which DNS registrar does the domain belong to?" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Expiration Date" tooltip="Domain expiration date if the domain is a TLD" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Auto Renew Enabled" tooltip="Is auto renew enabled for the domain?" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="TLS Cert Expiry" tooltip="Days till TLS certificate expires" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Valid MX" tooltip="Check if the domain is used for mailing by checking whether the domain has an MX record ➔ 'dig MX domain'"/>
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Mailing" tooltip="Mailing domain?" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="SPF" tooltip="SPF should contain 'v=spf1 include:_spf.google.com ~all' ➔ dig TXT domain" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="DKIM" tooltip="DKIM should be in place ➔ 'dig TXT google._domainkey.domain'" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="DMARC" tooltip="DMARC should be in place and equal to REJECT ➔ 'dig TXT _dmarc.domain'" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Status" tooltip="The status of the domain" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TooltipText text="Action" tooltip="Perform actions" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderExpirationDate(date, expiring, expired) {
        if ( expiring ){
            return (<Badge color="yellow" tooltip="Expiring in less than 3 months">{ date }</Badge>)
        }
        if ( expiring === false) {
            return (<Badge color="green">{ date }</Badge>)
        }
        if ( expired ) {
            return (<Badge color="red" tooltip="Expired">{ date }</Badge>)
        }
        else {
            return (<Badge color="blue">not a TLD</Badge>)
        }
    }

    renderAutoRenew(item) {
        if ( item ){
            return (<Badge color="green">✓</Badge>)
        }
        if ( item === false ){
            return (<Badge color="red">✗</Badge>)
        }
        else{
            return (<Badge color="blue">-</Badge>)
        }
    }

    renderTLSExpiry(item) {
        if ( item ) {
            if (item > 30) {
                return (<Badge color="green">{item}</Badge>)
            }
            if (item <= 30) {
                return (<Badge color="yellow">{item}</Badge>)
            }
            if (item <= 15) {
                return (<Badge color="red">{item}</Badge>)
            }
        } else{
            return (<Badge color="blue">-</Badge>)
        }
    }


    renderRow(item) {
        const pass = (<Badge color="green">✓</Badge>)
        const neutral = (<Badge color="blue">-</Badge>)
        const fail = (<Badge color="red">✗</Badge>)

        let markup_mx, markup_googlemail, markup_spf, markup_dkim, markup_dmarc;

        if (item.has_mx) {
            markup_mx = item.has_mx && item.has_googlemail ? pass : fail;
            markup_googlemail = item.has_googlemail ? pass : fail;
            markup_spf = item.has_spf ? pass : fail;
            markup_dkim = item.dkim_valid ? pass : fail;
            markup_dmarc = item.dmarc_valid ? pass : fail;
        }

        else {
            markup_mx = neutral;
            markup_googlemail = item.has_googlemail ? pass : neutral;
            markup_spf = item.spf_valid ? pass : neutral;
            markup_dkim = item.dkim_valid ? pass : neutral;
            markup_dmarc = item.dmarc_valid ? pass : fail;
        }

        if (item.has_mx && !item.has_googlemail){
            markup_mx = pass;
            markup_googlemail = fail;
            markup_spf = item.spf_valid ? pass : neutral;
            markup_dkim = item.dkim_valid ? pass : neutral;
            markup_dmarc = item.dmarc_valid ? pass : fail;
        }


        return (
            <TableRow key={item.name}>
                <TableCell>
                    {item.name}
                </TableCell>
                <TableCell className="text-center">
                    {item.source}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderExpirationDate(item.expiry_date, item.expiring, item.expired)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderAutoRenew(item.auto_renew)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderTLSExpiry(item.tls_expiry)}
                </TableCell>
                <TableCell className="text-center">
                    {markup_mx}
                </TableCell>
                <TableCell className="text-center">
                    {markup_googlemail}
                </TableCell>
                <TableCell className="text-center">
                    {markup_spf}
                </TableCell>
                <TableCell className="text-center">
                    {markup_dkim}
                </TableCell>
                <TableCell className="text-center">
                    {markup_dmarc}
                </TableCell>
                <TableCell className="text-center">
                    <Badge color={this.col_map[item.status]}
                           tooltip={newlineRender(item.status_message)}>{item.status}</Badge>
                </TableCell>
                <TableCell className="text-center">
                    <Flex
                        justifyContent="start"
                        className="space-x-3"
                    >
                        <Button variant='light'
                            icon={WindowIcon}
                            color="blue"
                            onClick={() => window.open('https://' + item.name, '_blank', 'noopener,noreferrer')}
                        >
                            Open
                        </Button>
                        <Button variant='light'
                            icon={GlobeAltIcon}
                            color="indigo"
                            onClick={() => window.open(`https://mxtoolbox.com/SuperTool.aspx?action=mx%3a${item.name}&run=toolpage`, '_blank', 'noopener,noreferrer')}
                        >
                            DNS
                        </Button>
                    </Flex>
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(DomainTable)