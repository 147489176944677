import React from 'react';
import {KPICard1Metric, KPICardMetrics, KPIDecorationColor} from "../kpi_components";
import {
    Badge,
    Card,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Grid,
    Col,
    Title, Button,
} from '@tremor/react';

import {get_url} from '../../const.js'
import {booleanToText} from "../utils/text_utils";
import {TooltipText} from "../text_compontents";
import {LoadingComponent, NoDataComponent} from "../status_components";
import {ExclamationCircleIcon, ExclamationTriangleIcon, TableCellsIcon} from "@heroicons/react/24/outline";
import {CSVLink} from "react-csv";


export default class IAMTable extends React.Component {

    state = {
        iam: null,
        iam_account_id: [],
        username: [],
        mfa: [],
        console: [],
        keys_non_rotated: [],
        user_type: [],
        kpis: null,
        kpi_account: []
    };


    componentDidMount() {
        fetch(get_url() + 'api/cloud/kpis')
            .then((response) => response.json())
            .then((kpis) => {
                this.setState({ kpis: kpis })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/cloud/iam_users')
            .then((response) => response.json())
            .then((iam) => {
                this.setState({ iam: iam })
            })
            .catch((error) => {
                console.error(error);
            });
    }


    render() {
        if (this.state.kpis === null || this.state.iam === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.kpis.length === 0 || this.state.iam.length === 0) {
            // Render loading UI ...
            return (
                <NoDataComponent/>
            );
        } else {
            // Render real UI ...

            let iam_list = this.state.iam
                .filter(item => this.state.username.length === 0 || this.state.username.includes(item.username))
                .filter(item => this.state.iam_account_id.length === 0 || this.state.iam_account_id.includes(item.account_id))
                .filter(item => this.state.console.length === 0 || this.state.console.includes(item.console_access_enabled))
                .filter(item => this.state.keys_non_rotated.length === 0 || this.state.keys_non_rotated.includes(this.renderKeyRotationValue(item.access_keys_non_compliant)))
                .filter(item => this.state.mfa.length === 0 || this.state.mfa.includes(item.mfa_enabled))
                .filter(item => this.state.user_type.length === 0 || this.state.user_type.includes(item.user_type))
            // IAM KPIs: Users
            const total_users = this.state.kpis.aws_iam.total_users;
            const total_users_with_console_access = this.state.kpis.aws_iam.total_users_with_console_access;
            const total_users_with_mfa_disabled = this.state.kpis.aws_iam.total_users_with_mfa_disabled;
            const total_users_with_non_rotated_access_keys = this.state.kpis.aws_iam.total_users_with_non_rotated_access_keys;
            const total_users_access_keys_non_rotated = this.state.kpis.aws_iam.total_users_access_keys_non_rotated;
            // IAM KPIs: Service Accounts
            const total_sa_with_non_rotated_access_keys = this.state.kpis.aws_iam.total_sa_with_non_compliant_access_keys;
            const total_sa = this.state.kpis.aws_iam.total_sa;
            const total_sa_with_console_access = this.state.kpis.aws_iam.total_sa_with_console_access;
            const total_sa_access_keys_non_rotated = this.state.kpis.aws_iam.total_sa_access_keys_non_rotated;

            return (
                    <Card>
                        <Title>Identity and Access Management (IAM)</Title>
                        <Grid numColsMd={1} className="gap-x-8 gap-y-6">
                            <Card className="mt-6">
                                <Title>Users</Title>
                                <Grid numColsMd={3} className="gap-x-9 gap-y-6 mt-6">
                                    <Col>
                                        {KPICard1Metric({ title: 'Total', value: total_users, color: 'green' })}
                                    </Col>
                                    <Col>
                                        {KPICard1Metric({ title: 'Console access', value: total_users_with_console_access, color: 'violet' })}
                                    </Col>
                                    <Col>
                                        {KPICard1Metric({ title: 'Without MFA', value: total_users_with_mfa_disabled, color: 'red' })}
                                    </Col>
                                </Grid>
                                <Grid numColsMd={1} className="gap-x-9 gap-y-6 mt-6">
                                    <Col>
                                        { KPICardMetrics({
                                            title: 'Users with unrotated Keys',
                                            title_metric: total_users_with_non_rotated_access_keys,
                                            title_icon: ExclamationCircleIcon,
                                            title_icon_color: KPIDecorationColor(total_users_with_non_rotated_access_keys, 0, -1),
                                            color: KPIDecorationColor(total_users_with_non_rotated_access_keys, 0, -1),
                                            items: [
                                                { icon: ExclamationTriangleIcon, icon_color: 'red', metric: total_users_access_keys_non_rotated, title: 'Total Keys'},
                                            ]
                                        }) }
                                    </Col>
                                </Grid>
                            </Card>
                            <Card className="mt-6">
                                <Title>Service Accounts</Title>
                                <Grid numColsMd={2} className="gap-x-9 gap-y-6 mt-6">
                                    <Col>
                                        {KPICard1Metric({ title: 'Total', value: total_sa, color: 'green' })}
                                    </Col>
                                    <Col>
                                        {KPICard1Metric({ title: 'Console access', value: total_sa_with_console_access, color: 'violet' })}
                                    </Col>
                                </Grid>
                                <Grid numColsMd={1} className="gap-x-9 gap-y-6 mt-6">
                                    <Col>
                                        { KPICardMetrics({
                                            title: 'Service Accounts with unrotated Keys',
                                            title_metric: total_sa_with_non_rotated_access_keys,
                                            title_icon: ExclamationCircleIcon,
                                            title_icon_color: KPIDecorationColor(total_sa_with_non_rotated_access_keys, 0, -1),
                                            color: KPIDecorationColor(total_sa_with_non_rotated_access_keys, 0, -1),
                                            items: [
                                                { icon: ExclamationTriangleIcon, icon_color: 'red', metric: total_sa_access_keys_non_rotated, title: 'Total Keys'},
                                            ]
                                        }) }
                                    </Col>
                                </Grid>
                            </Card>
                        </Grid>
                        <div>
                            <Title className='mt-6'>User Statistics</Title>
                            <Card className="mt-2">
                                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                    <CSVLink data={iam_list}
                                             filename="iam_raw_list.csv"
                                             target="_blank">
                                        <Button
                                            icon={TableCellsIcon}
                                            color="green"
                                        >
                                            Export to CSV
                                        </Button>
                                    </CSVLink>
                                </div>
                                {this.renderFiltersIam()}
                                <Table className="mt-5">
                                    {this.renderIamHeader()}
                                    <TableBody>
                                        {iam_list.map((item) => this.renderIAMRow(item))}
                                    </TableBody>
                                </Table>
                            </Card>
                        </div>
                    </Card>
            );
        }
    }

    renderFiltersIam() {
        let account_filter = Array.from(new Map(this.state.iam.map((item) => [item["account_id"], item["account_id"]])).values());
        let username_filter = Array.from(new Map(this.state.iam.map((item) => [item["username"], item["username"]])).values());
        let mfa_filter = Array.from(new Map(this.state.iam.map((item) => [item["mfa_enabled"], item["mfa_enabled"]])).values());
        let key_rotation_filter = Array.from(new Map(this.state.iam.map((item) => [this.renderKeyRotationValue(item["access_keys_non_compliant"]), this.renderKeyRotationValue(item["access_keys_non_compliant"])])).values());
        let console_filter = Array.from(new Map(this.state.iam.map((item) => [item["console_access_enabled"], item["console_access_enabled"]])).values());
        let user_type_filter = Array.from(new Map(this.state.iam.map((item) => [item["user_type"], item["user_type"]])).values());

        return (
            <Grid>
                <Text>Select filters</Text>
                <Grid numColsMd={3} className="gap-x-9 gap-y-6 mt-6">
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({iam_account_id: value})}
                            placeholder="Account number"
                            className="max-w-xs"
                        >
                            {account_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({username: value})}
                            placeholder="Username"
                            className="max-w-xs"
                        >
                            {username_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({mfa: value})}
                            placeholder="MFA"
                            className="max-w-xs"
                        >
                            {mfa_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({console: value})}
                            placeholder="Console"
                            className="max-w-xs"
                        >
                            {console_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({keys_non_rotated: value})}
                            placeholder="Keys rotated"
                            className="max-w-xs"
                        >
                            {key_rotation_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({user_type: value})}
                            placeholder="User type"
                            className="max-w-xs"
                        >
                            {user_type_filter.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>
                </Grid>
            </Grid>
        )
    }

    renderIamHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Account Number" tooltip="The AWS Account number" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Username" tooltip="AWS Account name" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Console" tooltip="Indicates if the user access by console" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Keys Rotated" tooltip="Indicates if the user has non rotated keys" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="MFA" tooltip="Indicates if the user uses MFA" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="User Type" tooltip="Indicates the user type User or Service Account" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderIAMRow(item) {
        return (
            <TableRow>
                <TableCell className="text-center">
                    {item.account_id}
                </TableCell>
                <TableCell className="text-center">
                    {item.username}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderBooleanConsole(item.console_access_enabled, item.mfa_enabled)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderKeyRotation(this.renderKeyRotationValue(item.access_keys_non_compliant, item.access_keys_active))}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderBoolean(item.mfa_enabled)}
                </TableCell>
                <TableCell className="text-center">
                    {item.user_type}
                </TableCell>
            </TableRow>)
    }

    renderBoolean(item) {
        if ( item ){
            return (<Badge color="green">✓</Badge>)
        }
        if ( item === false ){
            return (<Badge color="red">✗</Badge>)
        }
        else{
            return (<Badge color="blue">-</Badge>)
        }
    }
    renderBooleanConsole(console, mfa) {
        switch (console) {
            case false:
                return (<Badge color="green">No</Badge>)
            case true:
                if ( mfa ){
                    return (<Badge color="green">Yes</Badge>)
                }
                return (<Badge color="red">Yes</Badge>)
            default:
                return (<Badge color="blue">Yes</Badge>)
        }
    }
    renderKeyRotation(item) {
        if ( item === true) {
            return (<Badge color="green">✓</Badge>)
        }
        else{
            return (<Badge color="red">x</Badge>)
        }
    }
    renderKeyRotationValue(item, item2) {
        switch (item) {
            case "":
                return true
            default:
                return item2 === false;
        }
    }
}


