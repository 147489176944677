import {jsPDF} from 'jspdf';
import {toPng} from "html-to-image";

export async function downloadElementAs(type, element) {
    switch (type) {
        case 'pdf':
            await downloadElementAsPDF(element);
            break;
        case 'png':
            await downloadElementAsImage(element);
            break;
        case 'csv':
            await downloadElementAsCSV(element);
            break;
        default:
            console.error("Invalid download type " + type);
    }
}

async function downloadElementAsImage(element){
    let blob = await getElementAsPng(element)
    downloadStringAsFile(getName('png'), blob);
}

async function downloadElementAsPDF(element) {
    const data = await getElementAsPng(element)

    let width = element.clientWidth
    let height = element.clientHeight

    let orientation = width < height ? 'p' : 'l'

    // This will create a strange sized PDF but scaling an image to A4 page isn't working at the moment.
    const pdf = new jsPDF(orientation, 'pt', [width, height]);
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = pdf.internal.pageSize.getHeight()

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(getName('pdf'));
}

async function downloadElementAsCSV(element) {
    const table = element.querySelector('table');
    const tableBody = table.querySelector('tbody')
    // Create CSV string from table headers and rows
    let csv = '';

    // Get table headers and create header row for CSV
    const headers = table.querySelectorAll('th');
    const headerArray = Array.from(headers).map(header => `"${header.textContent.trim()}"`);
    csv += headerArray.join(',') + '\n';

    // Get table rows and iterate through each row
    const rows = tableBody.querySelectorAll('tr');
    rows.forEach(row => {
        const cells = row.querySelectorAll('td');
        const cellArray = Array.from(cells).map(cell => `"${cell.textContent.trim()}"`);
        csv += cellArray.join(',') + '\n';
    });

    // Download CSV
    const filename = getName('csv')
    downloadStringAsFile(filename, 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
}

function downloadStringAsFile(filename, data) {
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function filter(node) {
    // Filter function to ignore things that should not appear in pdf/image saving
    return !(node.classList && node.classList.contains('hiddenPrint'));
}

async function getElementAsPng(element) {
    return await toPng(element, {cacheBust: true, backgroundColor: "#ffffff", filter: filter});
}

function getName(extension) {
    let date = new Date().toLocaleDateString();
    return `PorkPie-${date}.${extension}`
}