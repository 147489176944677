import React from 'react';
import {
    Grid
} from '@tremor/react';

import AWSAccounts from "./table_aws_accounts";
import IAMTable from "./table_iam_monitoring";

export default class AWSTable extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <Grid numColsMd={2} className="gap-y-6 gap-x-7 mt-6">
                <AWSAccounts/>
                <IAMTable/>
            </Grid>
        );
    }
}


