import {
    Button, Callout, Card,
    Flex, Text, TextInput, Title
} from '@tremor/react';
import React from "react";
import {redirect, useLocation} from "react-router-dom";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {set_logged_in, set_logged_out} from "./auth";
import {ReactComponent as GoogleIcon } from "./google_icon.svg";

export default function LoginPage() {
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    let error = query.get("error");

    if (error){
        set_logged_out()
    }

    return (
        <div className=""
             style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh"}}>
            <main>
                <Flex justifyContent={"center"} className={"space-x-4"}>

                    <img src="/logo512.png" alt="Sad Porkpie" width="250"/>

                    <Card className="max-w-7xl text-center">
                        <form id="login-form" method="POST">
                            <div>
                                <Title>Porkpie Login</Title>

                                {error ? (<Callout
                                        className="h-12 mt-4"
                                        title="Login Failed"
                                        icon={ExclamationCircleIcon}
                                        color="rose"
                                    >
                                    </Callout>
                                ) : (<></>)}

                                <Text className="m-2">Username</Text>
                                <TextInput type="text" placeholder="Username" name="username" required/>
                                <Text className="m-2">Password</Text>
                                <TextInput type="password" placeholder="Password" name="password" required/>

                            <Button className="mt-4" onClick={ () => {
                                            set_logged_in()
                                            return false;
                                        }
                                    }>Login</Button>
                            </div>
                        </form>
                        <div>

                            <Button icon={GoogleIcon} color={"red"} className="mt-4" onClick={ () => {
                                        set_logged_in();
                                        window.location = '/google_auth';
                                    }
                            }>Sign in with Google</Button>

                        </div>
                    </Card>
                </Flex>
            </main>
        </div>
    );
}