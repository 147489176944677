import React from 'react';

import {TableCellsIcon} from "@heroicons/react/24/outline";

import {
    Badge,
    Card,
    Flex,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Italic,
    Text,
    Grid,
    Col,
    Title,
    Button
} from '@tremor/react';

import {BriefcaseIcon, ClipboardDocumentCheckIcon} from "@heroicons/react/24/outline";
import {get_url} from '../../const.js'
import {AccountRedirect} from "../text_compontents";
import {ConfigLineGraph, KPICardMetricsWithPercentIncrease, KPIDecorationColor} from "../kpi_components";
import {LoadingComponent, NoDataComponent} from "../status_components";
import {CSVLink} from "react-csv";


export default class AWSConfig extends React.Component {

    state = {
        metrics: null,
        kpis: null,
        dates: null
    };


    componentDidMount() {
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/cloud/kpis')
            .then((response) => response.json())
            .then((kpis) => {
                this.setState({kpis: kpis})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({dates: dates})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.metrics === null || this.state.kpis === null || this.state.dates == null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.metrics.length === 0 || this.state.kpis.length === 0) {
            // Render loading UI ...
            return (
                <NoDataComponent/>
            );
        } else {
            // Render real UI ...
            let account_list = this.state.kpis.config_conformance_pack_metrics.account_overall_conformance_pack;
            let conformance_pack_summary = this.state.kpis.config_conformance_pack_metrics.overall_conformance_pack_percent;
            const aws_config_first_of_month = this.state.metrics.config_compliance_first_of_month;
            const dates = this.state.dates

            return (
                <Grid numColsMd={2} numColsLg={3} className="gap-x-6 gap-y-6 mt-6">
                    <Col numColSpanLg={1}>
                        {KPICardMetricsWithPercentIncrease({
                            title: 'AWS Conformance Pack Compliance',
                            title_metric: conformance_pack_summary.pack_overall_average.toFixed(2) + "%",
                            today: conformance_pack_summary.pack_overall_average,
                            first_month: aws_config_first_of_month.at(-1),
                            title_icon: ClipboardDocumentCheckIcon,
                            title_icon_color: KPIDecorationColor(conformance_pack_summary.pack_overall_average, 95, 90, true),
                            color: KPIDecorationColor(conformance_pack_summary.pack_overall_average, 95, 90, true),
                            items: Object.keys(conformance_pack_summary)
                                .filter(pack => pack !== 'pack_overall_average')
                                .map(pack => (
                                    { icon: BriefcaseIcon, icon_color: 'blue', metric: conformance_pack_summary[pack].toFixed(2) + "%", title: pack }
                                ))
                        })}

                        {ConfigLineGraph({data: aws_config_first_of_month, title: "AWS Compliance Over Time"})}
                    </Col>


                    <Col numColSpanLg={2}>
                        <Card>
                            <Flex justifyContent='end'>
                                <div>
                                    <Text><Italic>Updated on: {dates.get_latest_date_conformance_pack_compliance_scores}</Italic></Text>
                                    &nbsp;&nbsp;&nbsp;
                                </div>
                            </Flex>
                            <Flex justifyContent='start'>
                                <Flex>
                                    <Title>AWS Config Accounts: {account_list.length}</Title>
                                </Flex>
                                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                    <CSVLink data={account_list}
                                             filename="config_compliance_status.csv"
                                             target="_blank">
                                        <Button
                                            icon={TableCellsIcon}
                                            color="green"
                                        >
                                            Export table to CSV
                                        </Button>
                                    </CSVLink>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                    icon={TableCellsIcon}
                                    color="green"
                                    onClick={() => window.open('https://docs.google.com/spreadsheets/d/1kiLTb0RodpwzspU818wlO5-7uWYGbqiFzooUEOe-O-A/', '_blank', 'noopener,noreferrer')}
                                >
                                    AWS Compliance Rules
                                </Button>
                            </Flex>
                            <Text><Italic>Click on an account name for more information</Italic></Text>
                            <Table className="mt-5">
                                {this.renderHeader()}
                                <TableBody>
                                    {account_list.map((item) => this.renderRow(item))}
                                </TableBody>
                            </Table>
                        </Card>
                    </Col>

                </Grid>
            );
        }
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell className="text-center">
                        Alias Account Name
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        # Non-Compliant Rules
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        # Conformance Packs
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        # Compliant Conformance Packs
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Overall Compliance
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderNumCompliantConformancePacks(num_packs, num_compliant_packs) {
        if (num_packs === num_compliant_packs) {
            return (<Badge color="green">{num_compliant_packs}</Badge>)
        }
        else{
            return (<Badge color="red">{num_compliant_packs}</Badge>)
        }
    }

    renderRulesNumNonCompliance(num_rules) {
        if (num_rules) {
            if (num_rules === 0) {
                return (<Badge color="green">{num_rules}</Badge>)
            } else {
                return (<Badge color="red">{num_rules}</Badge>)
            }
        } else {
            return (<Badge color="blue">n/a</Badge>)
        }
    }

    renderRow(item) {

        const conformance_pack_score_average = this.state.metrics.config_account_conformance_pack_score_average;
        const average = item.account_id in conformance_pack_score_average ?
            (conformance_pack_score_average[item.account_id]).toFixed(2) + "%" : "n/a"

        return (
            <TableRow>
                <TableCell className="text-center">
                    <AccountRedirect text={item.account_id} location="/aws_config_account"/>
                </TableCell>
                <TableCell className="text-center">
                    {this.renderRulesNumNonCompliance(item.rule_noncompliance)}
                </TableCell>
                <TableCell className="text-center">
                    {item.num_packs}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderNumCompliantConformancePacks(item.num_packs, item.num_pack_compliance)}
                </TableCell>
                <TableCell className="text-center">
                    {average}
                </TableCell>
            </TableRow>)
    }
}