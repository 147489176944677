import React from 'react';

import {ChartBarSquareIcon, TableCellsIcon} from "@heroicons/react/24/outline";


import {
    Badge,
    Button,
    Card, Grid,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title,
    Italic
} from '@tremor/react';

import {get_url} from '../../const.js'
import withLocation from "../../withLocation";
import {getFilterFromGetParams} from "../utils/query_utils";
import {createFooter, KPIAccordianProgressList, KPIDonut} from "../kpi_components";
import {booleanToText} from "../utils/text_utils";
import {LoadingComponent, NoDataComponent} from "../status_components";


class MonitoringTable extends React.Component {

    state = {
        systems: null,
        metrics: null,
        dates: null,
        nameFilter: getFilterFromGetParams(this.props, 'name', null),
        selectMonitoring: getFilterFromGetParams(this.props, 'monitored', []),
        selectIngress: getFilterFromGetParams(this.props, 'automated', []),
        selectSiem: getFilterFromGetParams(this.props, 'siem', []),
    };

    componentDidMount() {
        fetch(get_url() + 'api/system_monitoring')
            .then((response) => response.json())
            .then((systems) => {
                this.setState({systems: systems})
            })
            .catch((error) => {
                console.error(error);
            });

        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({dates: dates})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.systems === null || this.state.metrics === null || this.state.dates === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.systems.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        } else {

            let dates = this.state.dates
            const system_monitoring = this.state.metrics.systems_monitored;
            let num_systems_securonix = (system_monitoring.siem.securonix / system_monitoring.total_critical_systems)*100
            let num_systems_opensearch = (system_monitoring.siem.opensearch / system_monitoring.total_critical_systems)*100
            let num_systems_elk_stack = (system_monitoring.siem.elk_stack / system_monitoring.total_critical_systems)*100
            let num_systems_gcp_console = (system_monitoring.siem.gcp_console / system_monitoring.total_critical_systems)*100
            let num_systems_aws_console = (system_monitoring.siem.aws_console / system_monitoring.total_critical_systems)*100
            let num_systems_native_saas = (system_monitoring.siem.native_saas / system_monitoring.total_critical_systems)*100


            let active_monitoring = this.state.systems
                .filter(item => !this.state.nameFilter || item.system_name.toLowerCase().includes(this.state.nameFilter.toLowerCase()))
                .filter(item => this.state.selectMonitoring.length === 0 || this.state.selectMonitoring.includes(item.monitored.toString()))
                .filter(item => this.state.selectIngress.length === 0 || this.state.selectIngress.includes(item.automated_import.toString()))
                .filter(item => this.state.selectSiem.length === 0 || this.state.selectSiem.includes(item.siem.toString()));

            return (
                <>
                <Grid numColsMd={4} className="gap-x-6 gap-y-6 mt-6">
                    {KPIDonut(
                        {title: 'Critical Systems with Monitoring', data:
                                [
                                    { 'name': 'with monitoring', value: system_monitoring.total_critical_systems_monitored},
                                    { 'name': 'without monitoring', value: system_monitoring.total_critical_systems - system_monitoring.total_critical_systems_monitored},
                                ],
                            'legend': true,
                            'colors': ["blue", "yellow"]})}

                    {KPIDonut(
                        {title: 'Critical Systems that SIEMs ingest', data:
                                [
                                    { 'name': "Securonix", value: system_monitoring.siem.securonix},
                                    { 'name': "OpenSearch", value: system_monitoring.siem.opensearch},
                                    { 'name': "ELK Stack", value: system_monitoring.siem.elk_stack},
                                    { 'name' : "AWS Console", value: system_monitoring.siem.aws_console},
                                    { 'name' : "GCP Console", value: system_monitoring.siem.gcp_console},
                                    { 'name' : "Native SAAS", value: system_monitoring.siem.native_saas}
                                ],
                            'legend': true,
                            'colors': ["blue", "yellow", "slate", "orange", "red", "green"]})}

                    {KPIDonut(
                        {title: 'Critical Systems Logging', data:
                                [
                                    { 'name': 'automated ingestion', value: system_monitoring.automated_import.automated},
                                    { 'name': 'manual exported', value: system_monitoring.automated_import.manual},
                                ],
                            'legend': true,
                            'colors': ["green", "red"]})}

                    {KPIAccordianProgressList({
                        title: 'Critical Systems that are Monitored',
                        icon: ChartBarSquareIcon,
                        color: 'yellow',
                        value: system_monitoring.total_critical_systems_monitored,
                        total: system_monitoring.total_critical_systems,
                        description: "% Critical Systems that each SIEM is currently ingesting ",
                        data:
                            [
                                {
                                    name: 'Securonix',
                                    value:num_systems_securonix,
                                    description: "Total number of critical systems that have been ingested into Securonix"
                                },
                                {
                                    name: 'OpenSearch',
                                    value: num_systems_opensearch,
                                    description: "Total number of critical systems that have been ingested into OpenSearch"
                                },
                                {
                                    name: 'ELK Stack',
                                    value: num_systems_elk_stack,
                                    description: "Total number of critical systems that have been ingested into Elk Stack"
                                },
                                {
                                    name: 'AWS Console',
                                    value: num_systems_aws_console,
                                    description: "Total number of critical systems that have been ingested into Elk Stack"
                                },
                                {
                                    name: 'GCP Console',
                                    value: num_systems_gcp_console,
                                    description: "Total number of critical systems that have been ingested into Elk Stack"
                                },
                                {
                                    name: 'Native Saas',
                                    value: num_systems_native_saas,
                                    description: "Total number of critical systems that have been ingested into Elk Stack"
                                }
                            ],
                        extra: createFooter("View", ()=>{window.location='/monitoring?monitored=false'})
                    })}
                </Grid>


                <Card className="mt-6">
                    <Flex justifyContent='end'>
                        <div>
                            <Text><Italic>Updated on: {dates.get_latest_date_system_monitoring}</Italic></Text>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                    </Flex>
                    <Flex>
                        <Flex justifyContent='start'>
                            <Title>Systems: {active_monitoring.length}</Title><Text>&nbsp;/ {system_monitoring.total_critical_systems}</Text>
                        </Flex>
                        <Button
                            icon={TableCellsIcon}
                            color="green"
                            onClick={() => window.open('https://docs.google.com/spreadsheets/d/1fQZMRVZsa1o2bEEK3zQaNzYDx-_58WbeJMWJY0kDbgA/edit#gid=1004512551', '_blank', 'noopener,noreferrer')}
                        >
                            Google Sheet
                        </Button>
                    </Flex>


                    {this.renderFilters()}

                    <Table className="mt-5">

                        {this.renderHeader()}

                        <TableBody>
                            {active_monitoring.map((item) => this.renderRow(item))}
                        </TableBody>
                    </Table>
                </Card>
                </>
            );
        }
    }

    renderFilters() {
        let systems = Array.from(new Map(this.state.systems.map((item) => [item["monitored"], item["monitored"]])).values());

        return (


            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value})
                        }
                        }/>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectMonitoring: value})}
                            placeholder="Monitored"
                            className="max-w-xs"
                        >
                            {systems.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)}/>
                            ))}
                        </MultiSelectBox>

                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        Critical System
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Where are logs ingested?
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        Is the system monitored?
                    </TableHeaderCell>

                </TableRow>
            </TableHead>)
    }

    renderUnknown(tooltip) {
        return (<Badge color="fuchsia" tooltip={tooltip}>?</Badge>)
    }

    renderMonitored(monitored) {
        if (monitored) {
            return (<Badge color="green">✓</Badge>)
        }else if (!monitored){
            return (<Badge color="red">✗</Badge>)
        }else {
            return this.renderUnknown(monitored)
        }

    }

    renderSystemMonitored(system) {
        if (system) {
            return (system.replace("_", " ").replace(",",", "))
        }
        else {
            return (<Badge color="red">✗</Badge>)
        }
    }

    renderRow(item) {

        let keys = Object.keys(item).filter(k=>item[k]==="Yes") + "";

        return (
            <TableRow key={item.system_name} >
                <TableCell>
                    {item.system_name}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderSystemMonitored(keys)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderMonitored(item.monitored)}
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(MonitoringTable)
