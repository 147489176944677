import React from 'react';

import {
    Button,
    Card,
    Flex,
    Grid,
    Italic,
    Text, Title
} from '@tremor/react';

import { get_url } from '../../const.js'
import { KPIDonutNoLabel } from "../kpi_components";
import { LoadingComponent, NoDataComponent } from "../status_components";
import ConfigRules from './table_config_rules';

export default class AWSConfigAccountTable extends React.Component {

    state = {
        metrics: null,
        dates: null,
        accountName: null,
    };

    componentDidMount() {
        fetch(get_url() + 'api/config')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
                this.updateAccountName();
            })
            .catch((error) => {
                console.error(error);
            });

        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({ dates: dates })
                this.updateAccountName();
            })
            .catch((error) => {
                console.error(error);
            });
    }
    updateAccountName = () => {
        const id = localStorage.getItem("account_id");
        const { metrics } = this.state;

        if (metrics) {
            const data = metrics.find(data => data.account_id === id);
            if (data) {
                this.setState({ accountName: data.account_id });
            }
        }
    };
    render() {
        const id = localStorage.getItem("account_id");
        if (this.state.metrics === null || this.state.dates === null) {
            // Render loading UI ...
            return (
                <LoadingComponent />
            );
        } else if (this.state.metrics.length === 0) {
            // Render loading UI ...
            return (
                <NoDataComponent />
            );
        } else {
            // Render real UI ...
            let dates = this.state.dates
            let account_list = this.state.metrics
            const KPIDonutComponent = ({ accountData }) => (
                <div>
                    {accountData.map((data, index) => {
                        if (data.account_id === id) {
                            const conformancePackNames = Object.keys(data).filter(key => key !== "account_id" && key !== "average" && key !== "date");
                            return (
                                <Grid numColsMd={4} className="gap-x-6 gap-y-6 mt-6" key={index}>
                                    {conformancePackNames.map((conformancePack, subIndex) => {
                                        // Extract the dynamic part from the conformance pack name
                                        const dynamicPart = conformancePack.replace(/^OrgConformsPack-/, '').replace(/-[^-]+$/, '');
                                        return (
                                            <KPIDonutNoLabel
                                                key={subIndex}
                                                title={`${dynamicPart}`}
                                                data={[
                                                    { "name": "% compliance", value: data[conformancePack] },
                                                    { "name": "% non-compliance", value: 100 - data[conformancePack] }
                                                ]}
                                                legend={true}
                                                colors={["green", "yellow"]}
                                            />
                                        )
                                    })}
                                </Grid>
                            );
                        }
                        return null; // Ensure to return null when the condition is not met
                    })}
                </div>
            );

            return (

                <>
                    <Card className="gap-x-6 gap-y-6 mt-6">
                        <Flex justifyContent='end'>
                            <Text><Italic>Updated on: {dates.get_latest_date_conformance_pack_compliance_scores}</Italic></Text>
                        </Flex>
                        <Flex justifyContent='start'>
                            &nbsp;
                            <Button className="ml-2" onClick={() => {
                                window.location = '/aws_config';
                            }}>Return to AWS Compliance</Button><br />
                        </Flex>
                    </Card>
                    <>
                        <Card className="gap-x-6 gap-y-6 mt-6">
                            <Title>{this.state.accountName} Compliance</Title>
                        </Card>
                        <KPIDonutComponent accountData={account_list} />
                        <Card className="gap-x-6 gap-y-6 mt-6">
                            <Title>Non Compliant Rules Table</Title>
                            {<ConfigRules account_id_parameter={this.state.accountName} />}
                        </Card>
                    </>
                </>
            );
        }
    }
}
