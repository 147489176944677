import React from 'react';

import {TableCellsIcon, TicketIcon} from "@heroicons/react/24/outline";

import {
    Badge,
    Button,
    Card, Col, Grid,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title
} from '@tremor/react';

import {get_url} from '../../const.js'
import {newlineRender} from "../utils/text_utils";
import {TooltipText} from "../text_compontents";
import withLocation from "../../withLocation";
import {getFilterFromGetParams} from "../utils/query_utils";
import {NoDataComponent, LoadingComponent} from "../status_components";
import {KPIDonut, VulnsSourcesStackedBarChart, VulnsStackedBarChart} from "../kpi_components";
import KpiTabs from "../kpi_tabs";

class VulnTrackerTable extends React.Component {

    state = {
        vulns: null,
        metrics: null,
        nameFilter: getFilterFromGetParams(this.props, 'name', null),
        selectedSeverities: getFilterFromGetParams(this.props, 'severity', []),
        selectedStates: getFilterFromGetParams(this.props, 'status', []),
        selectedSources: getFilterFromGetParams(this.props, 'sources', [])
    };

    componentDidMount() {
        fetch(get_url() + 'api/vuln_tracker')
            .then((response) => response.json())
            .then((vulns) => {
                this.setState({vulns: vulns})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.vulns === null || this.state.metrics === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.vulns.length === 0 || this.state.metrics === 0) {
            // Render loading UI ...
            return (
                <NoDataComponent/>
            );
        } else {

            const vuln_tracker = this.state.metrics.vuln_tracker;

            // create list of vulnerabilities per cloud source for Tremor donut to ingest
            let vuln_cloud_sources = []
            for (let key of Object.keys(vuln_tracker.total_cloud)) {
                if (key !== "") {
                    vuln_cloud_sources.push({name: key, value: vuln_tracker.total_cloud[key]})
                }
            }
            // create list of vulnerabilities per severity for Tremor donut to ingest
            let vuln_severities = []
            for (let key of Object.keys(vuln_tracker.all.severities)) {
                vuln_severities.push({name: key, value: vuln_tracker.all.severities[key]})
            }


            // create list of open vulnerabilities for Tremor donut to ingest
            let vuln_open_severities = []
            for (let key of Object.keys(vuln_tracker.open.severities)) {
                vuln_open_severities.push({name: key, value: vuln_tracker.open.severities[key]})
            }

            // Render real UI ...
            let active_vulns = this.state.vulns
                .filter(item => this.state.nameFilter === null || (item.risk_vector + item.host + item.system).toLowerCase().includes(this.state.nameFilter))
                .filter(item => this.state.selectedSeverities.length === 0 || this.state.selectedSeverities.includes(item.severity.toLowerCase()))
                .filter(item => this.state.selectedStates.length === 0 || this.state.selectedStates.includes(item.status))
                .filter(item => this.state.selectedSources.length === 0 || this.state.selectedSources.includes(item.source));

            let stats = {'Fixed':0, 'Breached': 0, 'Not a Vulnerability': 0, 'Triaged': 0, 'Not Started': 0, 'Exception': 0, 'Unknown': 0}

            active_vulns.forEach((vuln) => {
                stats["Fixed"] += vuln.status === 'fixed' ? 1 : 0
                stats["Breached"] += vuln.status === 'breached' ? 1 : 0
                stats["Not a Vulnerability"] += vuln.status === 'not a vulnerability' ? 1 : 0
                stats["Triaged"] += ['triaged'].includes(vuln.status) ? 1 : 0
                stats["Not Started"] += ['not started', 'received'].includes(vuln.status) ? 1 : 0
                stats["Exception"] += ['under-exception', 'risk accepted','review-exception'].includes(vuln.status) ? 1 : 0
                stats["Unknown"] += [""].includes(vuln.status) ? 1 : 0
            })

            return (
                <>
                    <Grid numColsMd={2} numColsLg={3} className="gap-x-6 gap-y-6 mt-6">
                        <Col numColSpanLg={2}>
                        <KpiTabs
                                 tabs={[
                                     {
                                         title: "Severities",
                                         view: VulnsStackedBarChart({
                                             title: "Severities per month",
                                             subtitle: "Over the past 12 months",
                                             data: vuln_tracker.month_severities,
                                             months: -12,
                                             hide_card: true
                                         })
                                     },
                                     {
                                         title: "Sources",
                                         view: VulnsSourcesStackedBarChart({
                                             title: "Sources per month",
                                             subtitle: "Over the past 12 months",
                                             data: vuln_tracker.month_cloud,
                                             months: -12,
                                             hide_card: true
                                         })
                                     },
                                 ]}
                        />
                        </Col>

                        <Col numColSpanLg={1}>
                        {KPIDonut({
                            title: 'Total Vulnerabilities per Source',
                            data: vuln_cloud_sources,
                            'legend': true,
                            'colors': ["amber","cyan", "rose", "fuchsia",  "green", "violet", "pink", "stone"]
                    })}
                        </Col>
                        <Col numColSpanLg={1}>
                            {KPIDonut({
                                title: 'Open Vulnerabilities per Severity',
                                data: vuln_open_severities,
                                legend: true,
                                legend_colors: ["gray", "green", "orange", "red", "violet"],
                                colors: ["violet", "red", "orange", "green", "gray"],
                                reverse: true
                            })}
                        </Col>
                        <Col numColSpanLg={1}>
                            {KPIDonut({
                                title: 'Total Vulnerabilities per Severity',
                                data: vuln_severities,
                                legend: true,
                                legend_colors: ["gray", "green", "orange", "red", "violet"],
                                colors: ["violet", "red", "orange", "green", "gray"],
                                reverse: true
                            })}
                        </Col>
                  </Grid>

                    <Card className="mt-6">

                        <Flex>
                            <Flex justifyContent='start'>
                                <Title>Vulnerabilities: {active_vulns.length}</Title><Text>&nbsp;/ {this.state.vulns.length}</Text>
                            </Flex>

                            <Button
                                icon={TableCellsIcon}
                                color="green"
                                onClick={() => window.open('https://docs.google.com/spreadsheets/d/1pfvH17IK6EBJxPENvRlKTkjIwNjqC7A6zEw07-ZRci4', '_blank', 'noopener,noreferrer')}
                            >
                                Google Sheet
                            </Button>
                        </Flex>

                        {this.renderFilters()}

                        <Flex alignItems="items-start" justifyContent="start" className="mt-6 space-x-2">
                            <Badge color="green" tooltip="Fixed">{"✓ "+stats["Fixed"]}</Badge>
                            <Badge color="blue" tooltip="Breached">{"⚡"+stats["Breached"]}</Badge>
                            <Badge color="orange" tooltip="Risk Accepted">{"⚠ "+stats["Exception"]}</Badge>
                            <Badge color="yellow" tooltip="In Progress">{"► "+stats["Triaged"]}</Badge>
                            <Badge color="red" tooltip="Not Started">{"■ "+stats["Not Started"]}</Badge>
                            <Badge color="zinc" tooltip="No Action Required">{"✗ "+stats["Not a Vulnerability"]}</Badge>
                            <Badge color="indigo" tooltip="Unknown">{"? "+stats["Unknown"]}</Badge>
                        </Flex>

                        <Table className="mt-5">

                            {this.renderHeader()}

                            <TableBody>
                                {active_vulns.map((item) => this.renderRow(item))}
                            </TableBody>
                        </Table>
                    </Card>
                </>
            );
        }
    }

    renderFilters() {
        let sources = Array.from(new Map(this.state.vulns.map((item) => [item["source"], item["source"]])).values());
        let statuses = Array.from(new Map(this.state.vulns.map((item) => [item["status"], item["status"]])).values());
        let severities = ["CRITICAL", "HIGH", "MEDIUM", "LOW", ""]

        return (
            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <input type="text" placeholder="Filter" onChange={e => {
                            this.setState({nameFilter: e.target.value.toLowerCase()})
                        }
                        }/>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedStates: value})}
                            placeholder="Status"
                            className="max-w-xs"
                        >
                            {statuses.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedSeverities: value})}
                            placeholder="Severities"
                            className="max-w-xs"
                        >
                            {severities.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedSources: value})}
                            placeholder="Sources"
                            className="max-w-xs"
                        >
                            {sources.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                            ))}
                        </MultiSelectBox>

                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="First Seen" tooltip="The date when this issue was identified" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Vulnerability" tooltip="Type of vulnerability" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Host" tooltip="The system(s) the issue affects" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Team" tooltip="The team fixing the issue" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="MTTA" tooltip="Days taken to acknowledge issue" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="MTTR" tooltip="Days taken to resolve issue" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Severity" tooltip="The assessed level of risk for the issue" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Status" tooltip="The status of the issue" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Ticket" tooltip="Perform actions on the entry" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderUnknown(tooltip) {
        return (<Badge color="fuchsia" tooltip={tooltip}>?</Badge>)
    }

    renderSeverity(severity) {
        switch (severity) {
            case 'CRITICAL':
                return (<Badge color="purple">CRITICAL</Badge>)
            case 'HIGH':
                return (<Badge color="red">HIGH</Badge>)
            case 'MEDIUM':
                return (<Badge color="yellow">MEDIUM</Badge>)
            case 'LOW':
                return (<Badge color="green">LOW</Badge>)
            default:
                return this.renderUnknown(severity)
        }
    }

    renderStatus(status) {
        switch (status) {
            case 'fixed':
                return (<Badge color="green">{"✓ " + status}</Badge>)
            case 'breached':
                return (<Badge color="blue">{"⚡ " + status}</Badge>)
            case 'under-exception':
            case 'risk accepted':
            case 'review-exception':
                return (<Badge color="orange">{"⚠ " + status}</Badge>)
            case 'triaged':
                return (<Badge color="yellow">{"► " + status}</Badge>)
            case 'not started':
            case 'received':
                return (<Badge color="red">{"■ " + status}</Badge>)
            case 'not a vulnerability':
                return (<Badge color="zinc">{"✗ " + status}</Badge>)
            default:
                return this.renderUnknown(status)
        }
    }

    renderRiskVector(id, ref_id, description, environment) {

        let response
        if (environment) {
            response = `${environment}: ${id}`
        }
        else {
            response = id
        }
        return (<Badge color="blue" tooltip={`${ref_id}: ${description}`}>{newlineRender(response, 33)}</Badge>)
    }

    renderHost(host, cloud) {

        let response
        if (cloud) {
            response = `${cloud}: ${host}`
        }
        else {
            response = host
        }
        return newlineRender(response, 33)
    }

    renderTicket(ticket) {
        if (ticket) {
            return (<Button variant='light'
                icon={TicketIcon}
                color="blue"
                onClick={() => window.open('https://fxsolutions.atlassian.net/browse/' + ticket, '_blank', 'noopener,noreferrer')}
            >
                Ticket
            </Button>)
        }
        return (<></>)
    }

    renderRow(item) {

        return (
            <TableRow key={item.ref_id}>
                <TableCell className="text-center">
                    {newlineRender(item.first_seen, 30)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderRiskVector(item.risk_vector, item.ref_id, item.description, item.environment)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderHost(item.host, item.cloud)}
                </TableCell>
                <TableCell className="text-center">
                    {item.team}
                </TableCell>
                <TableCell className="text-center">
                    {item.time_to_acknowledge}
                </TableCell>
                <TableCell className="text-center">
                    {item.time_to_resolve}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderSeverity(item.severity)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderStatus(item.status)}
                </TableCell>
                <TableCell className="text-center">
                    <Flex
                        justifyContent="start"
                        className="space-x-3"
                    >
                        {this.renderTicket(item.task_no)}
                    </Flex>
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(VulnTrackerTable)