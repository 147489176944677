import {React, useState} from "react";
import {Card, Metric, Tab, TabList} from "@tremor/react";


export default function KpiTabs(props) {
  const [showCard, setShowCard] = useState(0);

  return (
      <Card className={props.className ? props.className : ""}>
      { props.title ? (<Metric>{props.title}</Metric>) : (<></>)}
      <TabList
        defaultValue={0}
        onValueChange={(value) => setShowCard(value)}
        className="mb-3"
      >
        {props.tabs.map((item, index) => (
                      <Tab value={index} text={item.title}/>
                  ))}
      </TabList>

      {props.tabs[showCard].view}
    </Card>
  );
};