import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter, Route, Routes,
} from "react-router-dom";

import './index.css';
import App from './App';
import ErrorPage from "./Error";
import {routes} from "./const";
import LoginPage from "./Login";
import {RequireAuth} from "./auth";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                {/* Routes are mapped to tabs in const.js */}
                {routes.map((item, index) => (
                    <Route path={item.route} element={
                      <RequireAuth redirectTo="/login">
                        <App activeTab={index}/>
                      </RequireAuth>
                    }/>
                ))}
                <Route path="/login" element={<LoginPage/>}/>
                {/* Display error page for all other routes */}
                <Route path="*" element={<ErrorPage/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);
