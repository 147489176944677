import {Navigate} from "react-router-dom";
import React from "react";

// This authenication check can be easily bypassed however, this is only a front-end application so there is no
// compromise of security. This functionality is more of a UX hint than a security implementation (which are not
// possible from client-side)

export function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = is_logged_in();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export function is_logged_in(){
  return localStorage.getItem('logged_in') || false;
}

export function set_logged_in(){
  return localStorage.setItem('logged_in', 'true');
}

export function set_logged_out(){
  localStorage.clear();
}
