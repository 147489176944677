import React from 'react';
import {
    Grid
} from '@tremor/react';

import {KPIAccordianProgressList, KPIDonut, VulnsSourcesStackedBarChart, VulnsStackedBarChart} from "../kpi_components";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {
    get_url,
    STRING_BRANCH_PROTECTIONS,
    STRING_DISMISS_STALE_REVIEWS,
    STRING_ENFORCE_ADMINS,
    STRING_REQUIRE_CODE_OWNER_REVIEWS, STRING_REQUIRE_LAST_PUSH_APPROVAL, STRING_STATUS_CHECK_STRICT
} from "../../const";
import GitHubRepositoryTable from "./table_github_repositories";
import GitHubBranchesTable from "./table_github_branches";
import KpiTabs from "../kpi_tabs";
import {LoadingComponent} from "../status_components";

export default class GitHubTable extends React.Component {

    state = {
        metrics: null
    };

    componentDidMount() {
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.metrics === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else {
            const github_repositories = this.state.metrics.github_repositories;
            const github_branches = this.state.metrics.github_branches;

            return (
                <>
                <Grid numColsMd={3} className="gap-x-6 gap-y-6 mt-6">
                    {KPIDonut({
                        title: "Active Repositories (last 6 months)",
                        data:
                        [
                            { 'name': 'Active', value: github_repositories.total_active },
                            { 'name': 'Inactive', value: github_repositories.total_outdated},
                        ],
                        legend: true,
                        'colors': ["green", "yellow"]
                    })}
                    {KPIDonut({
                        title: "Branch Protection Enabled Repositories",
                        data:
                        [
                            { 'name': 'Active', value: github_branches.branches },
                            { 'name': 'Inactive', value: github_branches.repositories - github_branches.branches},
                        ],
                        legend: true,
                        'colors': ["green", "red"]
                    })}
                    {KPIAccordianProgressList({title: 'Branch Protection Settings',
                            icon: CheckCircleIcon,
                            color: 'yellow',
                            value: github_branches.branches,
                            total: github_branches.repositories,
                            description: STRING_BRANCH_PROTECTIONS,
                            data:
                            [
                                {
                                    name: 'Enforce Admins',
                                    value: 100 * github_branches.enforce_admins / github_branches.repositories,
                                    description: STRING_ENFORCE_ADMINS
                                },
                                {
                                    name: 'Require Code Owner Reviews',
                                    value: 100 * github_branches.pr_require_code_owner_reviews / github_branches.repositories,
                                    description: STRING_REQUIRE_CODE_OWNER_REVIEWS
                                },
                                {
                                    name: 'Require Last Push Approval',
                                    value: 100 * github_branches.pr_require_last_push_approval / github_branches.repositories,
                                    description: STRING_REQUIRE_LAST_PUSH_APPROVAL
                                },
                                {
                                    name: 'Status Check Strict',
                                    value: 100 * github_branches.status_check_strict / github_branches.repositories,
                                    description: STRING_STATUS_CHECK_STRICT
                                },
                            ]
                        })}

                </Grid>

                    <KpiTabs
                        className={"mt-5"}
                        tabs={[
                            {
                                title: "Repositories",
                                view: (<GitHubRepositoryTable/>)
                            },
                            {
                                title: "Branches",
                                view: (<GitHubBranchesTable/>)
                            },
                        ]}
                    />
                </>

            );
        }
    }
}
