import React from 'react';

import {
    Badge,
    Card,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title,
    Grid,
    Italic
} from '@tremor/react';

import { get_url } from '../../const.js'
import {TooltipText} from "../text_compontents";
import withLocation from "../../withLocation";
import {ColouredBarChart, KPIDonut} from "../kpi_components";
import {booleanToText, networkSoftware} from "../utils/text_utils";
import {getFilterFromGetParams} from "../utils/query_utils";
import {NoDataComponent, LoadingComponent} from "../status_components";


class MerakiTable extends React.Component {

    state = {
        meraki: null,
        metrics: null,
        dates: null,
        selectedSeverity: getFilterFromGetParams(this.props, 'severity', []),
        selectedWireless: getFilterFromGetParams(this.props, 'wireless', []),
        selectedSwitch: getFilterFromGetParams(this.props, 'switch', []),
        selectedAppliance: getFilterFromGetParams(this.props, 'appliance', []),
        selectedSwitchCatalyst: getFilterFromGetParams(this.props, 'switch_catalyst', []),
        selectedUptodate: getFilterFromGetParams(this.props, 'up_to_date', []),
    };

    componentDidMount() {
        fetch(get_url() + 'api/meraki')
            .then((response) => response.json())
            .then((meraki) => {
                this.setState({ meraki: meraki })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/kpis')
            .then((response) => response.json())
            .then((metrics) => {
                this.setState({ metrics: metrics })
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/dates')
            .then((response) => response.json())
            .then((dates) => {
                this.setState({dates: dates})
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        if (this.state.meraki === null || this.state.metrics === null || this.state.dates === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.meraki.length === 0 || this.state.metrics === 0) {
            return (
                <NoDataComponent/>
            );
        } else {
            // Render real UI ...
            const dates = this.state.dates;
            const meraki = this.state.metrics.meraki;

            let active_meraki = this.state.meraki
                .filter(item => this.state.selectedSeverity.length === 0 || this.state.selectedSeverity.includes(item.health_severity))
                .filter(item => this.state.selectedWireless.length === 0 || this.state.selectedWireless.includes(item.wireless))
                .filter(item => this.state.selectedSwitch.length === 0 || this.state.selectedSwitch.includes(item.switch))
                .filter(item => this.state.selectedAppliance.length === 0 || this.state.selectedAppliance.includes(item.appliance))
                .filter(item => this.state.selectedSwitchCatalyst.length === 0 || this.state.selectedSwitchCatalyst.includes(item.switch_catalyst))
                .filter(item => this.state.selectedUptodate.length === 0 || this.state.selectedUptodate.includes(item.up_to_date.toString()));

            return (
                <>
                    <Grid numColsMd={3} className="gap-x-6 gap-y-6 mt-6">
                        {KPIDonut({
                            title: 'Offices with a Corporate Network', data:
                                [
                                    { 'name': 'with Meraki', value: meraki.total_meraki_offices },
                                    { 'name': 'without Meraki', value: meraki.total_offices - meraki.total_meraki_offices},
                                ],
                            'legend': true,
                            'colors': ["cyan", "violet", "slate"]
                        })}
                        {ColouredBarChart({title: "Meraki Product Software Versions",
                                  subtitle: "Software Versions by date",
                                  data: meraki.software_versions,
                                  categories: ['all_up_to_date', '1_product_outdated', '>1_product_outdated'] })}

                    </Grid>
                    <Card className="mt-6">
                        <Flex justifyContent='end'>
                            <div>
                                <Text><Italic>Updated on: {dates.get_latest_date_meraki}</Italic></Text>
                            </div>
                        </Flex>
                        <Flex justifyContent='start'>
                            <Title>Corporate Networks: {active_meraki.length}</Title><Text>&nbsp;/ {meraki.total_meraki}</Text>
                        </Flex>

                        {this.renderFilters()}

                        <Table className="mt-5">
                            {this.renderHeader()}
                            <TableBody>
                                {active_meraki.map((item) => this.renderRow(item))}
                            </TableBody>
                        </Table>
                    </Card>
                </>
            );
        }
    }

    renderFilters() {
        let severity = Array.from(new Map(this.state.meraki.map((item) => [item["health_severity"], item["health_severity"]])).values());
        let wireless = Array.from(new Map(this.state.meraki.map((item) => [item["wireless"], item["wireless"]])).values());
        let appliance = Array.from(new Map(this.state.meraki.map((item) => [item["appliance"], item["appliance"]])).values());
        let switches = Array.from(new Map(this.state.meraki.map((item) => [item["switch"], item["switch"]])).values())
        let switch_catalyst = Array.from(new Map(this.state.meraki.map((item) => [item["switch_catalyst"], item["switch_catalyst"]])).values())
        let outdated = Array.from(new Map(this.state.meraki.map((item) => [item["up_to_date"], item["up_to_date"]])).values());

        return (
            <div>
                <Text>Select filters</Text>

                <div className="max-w-xs">
                    <Flex alignItems="items-stretch" className="space-x-3">

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({ selectedSeverity: value })}
                            placeholder="Healthcheck Severity"
                            className="max-w-xs"
                        >
                            {severity.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={item} />
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({ selectedWireless: value })}
                            placeholder="Wireless"
                            className="max-w-xs"
                        >
                            {wireless.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={networkSoftware(item)} />
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({selectedSwitch: value})}
                            placeholder="Switch"
                            className="max-w-xs"
                        >
                            {switches.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={networkSoftware(item)}/>
                            ))}
                        </MultiSelectBox>

                        <MultiSelectBox
                            onValueChange={(value) => this.setState({ selectedAppliance: value })}
                            placeholder="Appliance"
                            className="max-w-xs"
                        >
                            {appliance.map((item) => (
                                <MultiSelectBoxItem key={item} value={item} text={networkSoftware(item)} />
                            ))}
                        </MultiSelectBox>
                        <MultiSelectBox
                            onValueChange={(value) => this.setState({ selectedUptodate: value })}
                            placeholder="Up-to-date"
                            className="max-w-xs"
                        >
                            {outdated.map((item) => (
                                <MultiSelectBoxItem key={item} value={item.toString()} text={booleanToText(item)} />
                            ))}
                        </MultiSelectBox>

                    </Flex>
                </div>
            </div>
        )
    }

    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell>
                        Location
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TooltipText text="Wireless Up-to-date?" tooltip="What stable version should the wireless product be updated to?" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TooltipText text="Switch Up-to-date?" tooltip="What stable version should the switch product be updated to?" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TooltipText text="Appliance Up-to-date?" tooltip="What stable version should the appliance product be updated to?" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <TooltipText text="Number of SSIDs" tooltip="How many SSIDs does each network have?" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderUpdated(item) {
        if (item === "up-to-date") {
            return (<Badge color="green">✓</Badge>)
        } else if (item === "not enabled") {
            return (<Badge color="blue">-</Badge>)
        } else {
            return <Badge color="red">{item}</Badge>
        }
    }

    renderSSID(item) {
        if (item === 4) {
            return (<Badge color="green">{item}</Badge>)
        } else {
           return (<Badge color="yellow">{item}</Badge>)
        }
    }

    renderRow(item) {

        return (
            <TableRow key={item.location}>
                <TableCell>
                    {item.location}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderUpdated(item.wireless)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderUpdated(item.switch)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderUpdated(item.appliance)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderSSID(item.ssids)}
                </TableCell>
            </TableRow>)
    }
}

export default withLocation(MerakiTable)
