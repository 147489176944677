import React from 'react';

import {TableCellsIcon} from "@heroicons/react/24/outline";

import {
    Badge,
    Button,
    Card,
    Flex,
    MultiSelectBox,
    MultiSelectBoxItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Grid,
    Col,
    Title,
} from '@tremor/react';

import {get_url} from '../../const.js'
import {TooltipText} from "../text_compontents";
import {KPIDonut} from "../kpi_components";
import {LoadingComponent, NoDataComponent} from "../status_components";
import {CSVLink} from 'react-csv';


export default class AWSAccounts extends React.Component {

    state = {
        accounts: null,
        account_id: [],
        name: [],
        environment: [],
        iam: null,
        iam_account_id: [],
        username: [],
        mfa: [],
        console: [],
        keys_non_rotated: [],
        kpis: null,
        kpi_account: []
    };


    componentDidMount() {
        fetch(get_url() + 'api/cloud/accounts')
            .then((response) => response.json())
            .then((accounts) => {
                this.setState({accounts: accounts})
            })
            .catch((error) => {
                console.error(error);
            });
        fetch(get_url() + 'api/cloud/kpis')
            .then((response) => response.json())
            .then((kpis) => {
                this.setState({ kpis: kpis })
            })
            .catch((error) => {
                console.error(error);
            });
    }


    render() {
        if (this.state.accounts === null || this.state.kpis === null) {
            // Render loading UI ...
            return (
                <LoadingComponent/>
            );
        } else if (this.state.accounts.length === 0 || this.state.kpis.length === 0) {
            // Render loading UI ...
            return (
                <NoDataComponent/>
            );
        } else {
            // Render real UI ...
            let account_list = this.state.kpis.accounts_iam_metrics
                .filter(item => this.state.name.length === 0 || this.state.name.includes(item.account_alias + " (" + item.account_id + ")"))
                .filter(item => this.state.environment.length === 0 || this.state.environment.includes(item.environment))
            let stats = {'ProductiveAccounts':0, 'PreAccounts': 0}
            account_list.forEach((acc) => {
                stats["ProductiveAccounts"] += acc.environment === 'prod' ? 1 : 0
                stats["PreAccounts"] += acc.environment === 'pre' ? 1 : 0
            })

            return (
                    <Card>
                        <Title>AWS Accounts ({account_list.length})</Title>
                            {this.renderStats(stats)}
                            <Card className="mt-6">
                                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                    <CSVLink data={account_list}
                                             filename="aws_accounts_status.csv"
                                             target="_blank">
                                        <Button
                                            icon={TableCellsIcon}
                                            color="green"
                                        >
                                            Export to CSV
                                        </Button>
                                    </CSVLink>
                                </div>
                                {this.renderFilters()}
                                <Table className="mt-5">
                                    {this.renderHeader()}
                                    <TableBody>
                                        {account_list.map((item) => this.renderRow(item))}
                                    </TableBody>
                                </Table>
                            </Card>

                            <Col numColSpan={3} numColSpanLg={2}>
                                <Grid numColsMd={2} className="gap-y-6 gap-x-7 mt-6">
                                {account_list.map(k=>{
                                    if(k.non_compliant_keys != "-") return(
                                        KPIDonut({
                                            title: k.account_alias, data:
                                                [
                                                    { 'name': 'Console Access Users', value: k.console_access_user_count},
                                                    { 'name': 'MFA Disabled Users', value: k.mfa_disabled_users },
                                                    { 'name': 'Keys Non rotated', value: k.non_compliant_keys},
                                                    { 'name': 'Users With Keys Non rotated', value: k.users_with_non_compliant_access_keys},
                                                    { 'name': 'Service Accounts With Keys Non rotated', value: k.service_accounts_with_non_compliant_access_keys},
                                                ],
                                            'legend': true,
                                            'colors': ["yellow", "red", "violet", "orange", "purple"]
                                        }))
                                })
                                }
                                </Grid>
                            </Col>
                    </Card>

            );
        }
    }
    renderFilters() {
        let environment_filter = Array.from(new Map(this.state.accounts.map((item) => [item["environment"], item["environment"]])).values());
        let account_id_filter = Array.from(new Map(this.state.accounts.map((item) => [item["account_alias"] + " (" + item["account_id"] + ")", item["account_alias"] + " (" + item["account_id"] + ")"])).values());
        return (
                <div>
                    <Text>Select filters</Text>
                    <div className="max-w-xs">
                        <Flex alignItems="items-stretch" className="space-x-3">

                            <MultiSelectBox
                                onValueChange={(value) => this.setState({environment: value})}
                                placeholder="Environment"
                                className="max-w-xs"
                            >
                                {environment_filter.map((item) => (
                                    <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                                ))}
                            </MultiSelectBox>

                            <MultiSelectBox
                                onValueChange={(value) => this.setState({name: value})}
                                placeholder="Account"
                                className="max-w-xs"
                            >
                                {account_id_filter.map((item) => (
                                    <MultiSelectBoxItem key={item} value={item} text={item === '' ? '?' : item}/>
                                ))}
                            </MultiSelectBox>
                        </Flex>
                    </div>
                </div>
        )
    }


    renderStats(stats) {
        return (
            <Grid numColsMd={2} className="gap-y-6 gap-x-9 mt-6">
                <Col>
                    <Button
                    icon={TableCellsIcon}
                    color="blue"
                    onClick={() => window.open('https://seceng.ebury.rocks/cloud/aws/', '_blank', 'noopener,noreferrer')}
                    >
                        Accounts Inventory
                    </Button>
                </Col>
                <Col>
                    <Badge color="green" tooltip="Productive Accounts">{"Prod Accounts "+stats["ProductiveAccounts"]}</Badge>
                    <Badge color="yellow" tooltip="Preproductive Accounts">{"Dev Accounts " +stats["PreAccounts"]}</Badge>
                </Col>
            </Grid>
        )
    }
    renderHeader() {
        return (
            <TableHead>
                <TableRow>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Account" tooltip="The AWS Account" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Console" tooltip="Users with Console Access" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="User Keys" tooltip="Keys not rotated in 90 days" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Service Accounts Keys" tooltip="Service Account keys not rotated in 90 days" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="No MFA" tooltip="Users without MFA" />
                    </TableHeaderCell>
                    <TableHeaderCell className="text-center">
                        <TooltipText text="Environment" tooltip="Indicates if the account is productive" />
                    </TableHeaderCell>
                </TableRow>
            </TableHead>)
    }

    renderRow(item) {
        return (
            <TableRow>
                <TableCell className="text-center">
                    {item.account_alias + " (" + item.account_id + ")"}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderIntegers(item.console_access_user_count)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderIntegers(item.users_with_non_compliant_access_keys)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderIntegers(item.service_accounts_with_non_compliant_access_keys)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderIntegers(item.mfa_disabled_users)}
                </TableCell>
                <TableCell className="text-center">
                    {this.renderEnvironment(item.environment)}
                </TableCell>
            </TableRow>)
    }

    renderEnvironment(Environment) {
        switch (Environment) {
            case "pre":
                return (<Badge color="yellow">Dev</Badge>)
            case "prod":
                return (<Badge color="green">Prod</Badge>)
            default:
                return (<Badge color="green">?</Badge>)
        }
    }

    renderIntegers(item) {
        if ( item === 0) {
            return (<Badge color="green" tooltip="All checks passed">✓</Badge>)
        }
        if ( item > 0) {
            return (<Badge color="red">{ item }</Badge>)
        }
        return (<Badge color="green" tooltip="All checks passed">✓</Badge>)
    }
}


